import { useEffect, useState } from 'react';
import { fetchAndActivate, RemoteConfig } from 'firebase/remote-config';

export type DynamicSdkConfig = {
  [key: string]: any;
};

/**
 * Helper for keeping an app's `LeagueConfig` object up-to-date with
 * Firebase remote config values.
 *
 * Given a function that returns a `LeagueConfig` object, will immediately return
 * whatever that function returns (a "default" value), but will also
 * call that function again once Firebase remote config values are fetched,
 * updating the return value accordingly.
 *
 * The function can use Firebase Remote Config getters internally, and this way
 * the values returned by those getters will reflect the remote values as soon
 * as they are available.
 */
export const useLeagueConfigFirebaseUpdater = (
  firebaseRemoteConfig: RemoteConfig,
  configGetter: () => DynamicSdkConfig,
): DynamicSdkConfig => {
  const [config, setConfig] = useState(configGetter);

  useEffect(() => {
    fetchAndActivate(firebaseRemoteConfig).then(() => {
      const newConfig = configGetter();
      setConfig(newConfig);
    });
  }, [configGetter, firebaseRemoteConfig]);

  return config;
};
