import * as React from 'react';
import {
  Box,
  css,
  Flex,
  genesisStyled,
  HeadingOne,
  Link,
} from '@leagueplatform/genesis-commons';
import { BackButtonArrow } from '@leagueplatform/web-common-components';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';

const StyledTitleContainer = genesisStyled(Box)(
  css({
    '&&': {
      borderBottomColor: 'onSurface.border.subdued',
      borderBottomStyle: 'solid',
      borderBottomWidth: 'thick',
      flex: '1',
    },
  }),
);

export const HsaHeader = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  if (isMobile) {
    return (
      <Box marginTop="one">
        <BackButtonArrow
          data-testid="back-button"
          onClick={() => history.push('/home')}
          as={Link}
        />
        <HeadingOne marginY="oneAndHalf">
          {formatMessage({ id: 'HSA_SUBHEADER' })}
        </HeadingOne>
      </Box>
    );
  }

  return (
    <Flex marginTop="oneAndHalf" marginBottom="three" width="100%">
      <BackButtonArrow
        data-testid="back-button"
        onClick={() => history.push('/home')}
        as={Link}
        marginY="quarter"
        marginRight="one"
      />
      <StyledTitleContainer>
        <HeadingOne marginBottom="two">
          {formatMessage({ id: 'HSA_SUBHEADER' })}
        </HeadingOne>
      </StyledTitleContainer>
    </Flex>
  );
};
