import React from 'react';
import {
  BodyOne,
  Caption,
  Flex,
  HeadingOne,
  HeadingTwo,
  Image,
} from '@leagueplatform/genesis-commons';
import lineBreak from 'assets/walkthrough-slides/line-break.png';
import { useIntl } from '@leagueplatform/locales';

export type SlideData = {
  slideImages: { [index: number]: string };
  slideHeadingContents: { [index: number]: string };
  slideBodyContents: { [index: number]: string };
};

export const WalkthroughSlideContent = ({
  slideValue,
  slideData,
  logo,
}: {
  slideValue: number;
  slideData: SlideData;
  logo: string;
}) => {
  const { formatMessage } = useIntl();

  return (
    <Flex flexDirection="column" alignItems="center" width="330px">
      <Image
        src={slideData.slideImages[slideValue]}
        alt=""
        width="176px"
        height="auto"
        marginBottom="two"
      />
      {slideValue === 0 && (
        <Image
          src={lineBreak}
          alt=""
          width="56px"
          height="auto"
          marginBottom="twoAndHalf"
        />
      )}
      {slideValue === 0 ? (
        <HeadingOne marginBottom="three">
          {slideData.slideHeadingContents[slideValue]}
        </HeadingOne>
      ) : (
        <HeadingTwo marginBottom="half">
          {slideData.slideHeadingContents[slideValue]}
        </HeadingTwo>
      )}

      {slideData.slideBodyContents[slideValue] && (
        <BodyOne textAlign="center">
          {slideData.slideBodyContents[slideValue]}
        </BodyOne>
      )}
      {slideValue === 0 && (
        <>
          <Caption
            color="onSurface.text.subdued"
            marginTop="twoAndHalf"
            marginBottom="half"
          >
            {formatMessage({ id: 'PROVIDED_BY_WITH_COLON' })}
          </Caption>
          {logo && (
            <Image
              src={logo}
              alt="Beneficity Logo"
              width="100px"
              height="auto"
            />
          )}
        </>
      )}
    </Flex>
  );
};
