import { v4 as uuidv4 } from 'uuid';

import {
  ErrorTypes,
  EventTypes,
  AgentChatEvents,
  UserChatEvents,
  LiveChatClient,
  ChatEndedReason,
  ChatEndedReasonTypes,
} from '@leagueplatform/live-chat';

import { DEFAULT_PARTNER_ID } from 'common/constants';
import { IdentityToken } from 'utils/class-identity-token';

import {
  EnGenChat,
  ChatConfig,
  Environments,

  // Types
  ChatError,
  ChatParticipant,
  StatusChange,
  ProviderAvailability,
  StatusTypes,
  ParticipantMessage,
  MessageType,
  ButtonContent,
  CardContent,
  EndChatStatus,
  MessageAcknowledgement,
  ParticipantLeave,
  ParticipantJoin,
  Parameter,
} from '../../engen-chat-sdk';

type UnacknowledgedMessage = {
  messageId: string;
  eventId: string;
};

type Config = {
  [key: string]: any;
};

export class ChatInstance {
  status: StatusTypes;

  config: Config;

  unacknowledgedMessages: UnacknowledgedMessage[];

  constructor() {
    this.status = StatusTypes.READY;
    this.unacknowledgedMessages = [];
    this.config = {};
  }

  setStatus(newStatus: StatusTypes) {
    if (
      newStatus === StatusTypes.READY &&
      this.status === StatusTypes.CONNECTING
    ) {
      return;
    }
    this.status = newStatus;
  }

  getStatus() {
    return this.status;
  }

  addUnacknowledgedMessage(messageId: string, eventId: string) {
    this.unacknowledgedMessages.push({ messageId, eventId });
  }

  acknowledgeMessage(messageId: string) {
    // Find the index for the acknowledgement message.
    const index = this.unacknowledgedMessages.findIndex(
      (message: UnacknowledgedMessage) => message.messageId === messageId,
    );
    // If it exists inside of the unacknowledged messages
    if (index > -1) {
      // Splice it out of the array
      const acknowledgeMessage = this.unacknowledgedMessages.splice(index, 1);
      // Return the eventId
      return acknowledgeMessage[0].eventId;
    }
    return null;
  }

  setConfig(newConfig: Config) {
    this.config = newConfig;
  }

  getConfigValue(key: string) {
    return this.config[key] || null;
  }

  getLinkValue = (link: ButtonContent) => {
    if (
      link?.action?.link?.linkType === 'INTERNAL' &&
      link?.action?.link?.source === 'CONFIG'
    ) {
      const linkValue = this.getConfigValue(link.action.link.value);
      if (linkValue) {
        return linkValue;
      }
      const defaultLink = this.getConfigValue('DEFAULT_DEEPLINK');
      if (defaultLink) {
        return `${window.location.origin}${defaultLink}`;
      }
      return `${window.location.origin}/`;
    }

    return link.action.link?.value ?? null;
  };

  // Gets links for messages with a type of Card
  getMessageLinks = (links: ButtonContent[]) =>
    links.map((link: ButtonContent) => {
      const linkValue = this.getLinkValue(link);
      return {
        value: linkValue,
        label: link.text ?? null,
      };
    });
}

export type EnGenLiveChatSettingsEnv = 'staging' | 'production';
export interface EnGenLiveChatSettings {
  env: EnGenLiveChatSettingsEnv;
  partnerId?: string;
}

const isStage = (env: EnGenLiveChatSettingsEnv) => env === 'staging';

export const createLiveChatClient = ({
  env,
  partnerId,
}: EnGenLiveChatSettings): LiveChatClient => {
  const chatEnvironment = isStage(env) ? Environments.TEST : Environments.PROD;
  const chatConfig: ChatConfig = new ChatConfig(chatEnvironment);
  const chatSDK = new EnGenChat(chatConfig);

  const identityToken = new IdentityToken(partnerId ?? DEFAULT_PARTNER_ID);
  const chatInstance = new ChatInstance();

  // Gets sender for messages
  const getMessageSender = (participant: ChatParticipant) => ({
    id: `${participant?.id ?? uuidv4()}`,
    name: participant?.displayName,
    avatarUrl: participant?.icon?.url?.value,
  });

  // Gets suggestion chips for messages
  const getMessageChips = (suggestions: ButtonContent[]) =>
    suggestions.map((suggestion: ButtonContent) => ({
      label: suggestion.text,
      value: suggestion.action.event?.name || suggestion.text,
      link: suggestion.action.link,
      metadata: suggestion.action.event?.parameters ?? [],
    }));

  // Gets card content for messages with a type of Card
  const getMessageCard = (card: CardContent) => {
    const buttons = chatInstance.getMessageLinks(card.buttons);
    return {
      title: card.title,
      subtitle: card?.subtitle,
      buttons,
    };
  };

  // Checks availability
  const checkAvailability = async (defaultProvider: string) => {
    const freshIdentityToken = await identityToken.getToken();
    if (freshIdentityToken) {
      try {
        const allProviders =
          await chatSDK.checkAvailability(freshIdentityToken);
        // Right now we're just expecting the provider to be chatbot
        // Eventually we'll be passing this from the BE
        // TODO: PCHAT-3345
        const availableProvider = allProviders.filter(
          (providerAvailability: ProviderAvailability) =>
            providerAvailability.provider.providerId === defaultProvider &&
            providerAvailability.isAvailable,
        );
        if (availableProvider.length > 0) {
          return availableProvider[0].provider.providerId;
        }
        return null;
      } catch (err) {
        return null;
      }
    }
    return null;
  };

  // Starts chat
  const startChat = async (providerId: string) => {
    const freshIdentityToken = await identityToken.getToken();
    if (freshIdentityToken) {
      chatSDK.startChat(freshIdentityToken, providerId, {});
    }
  };

  const sendMessage = async (message: string) => {
    const freshIdentityToken = await identityToken.getToken();
    if (freshIdentityToken) {
      const messageId = await chatSDK.sendMessage(freshIdentityToken, message);
      return messageId;
    }
    return null;
  };

  const sendChip = async (chip: {
    label: string;
    value: string;
    metadata: { [key: string]: any };
  }) => {
    const freshIdentityToken = await identityToken.getToken();
    if (freshIdentityToken) {
      let parameters: Parameter[] = [];

      // If chip.metadata is an object of key/values, transform to expected Parameters[] format
      // This is to match live-chat SDK's Chip metadata format to enGen's Event parameters
      // If chip.metadata is already an array, assume it's matching the same format already
      if (Array.isArray(chip.metadata)) {
        parameters = chip.metadata;
      } else if (chip.metadata) {
        parameters = Object.entries(chip.metadata).map(([key, value]) => ({
          name: key,
          value,
        }));
      }

      const messageId = await chatSDK.sendEvent(freshIdentityToken, {
        name: chip.value,
        parameters,
      });
      return messageId;
    }
    return null;
  };

  const getChatEndedReason = (status: EndChatStatus) => {
    const reasons = {
      PROVIDER: { type: ChatEndedReasonTypes.AGENT_DISCONNECTED },
      CLIENT: { type: ChatEndedReasonTypes.USER_DISCONNECTED },
      ERROR: { type: ChatEndedReasonTypes.CONNECTION_LOST },
    };
    return reasons[status.origin] || reasons.ERROR;
  };

  return {
    subscribeToAgentChatEvents: (handler: (event: AgentChatEvents) => void) => {
      // Per the EnGen docs the provider will send an isTyping event when an agent starts typing
      // We can expect any subsequent isTyping events over the next 5 seconds if the agent keeps typing
      // The event should be automatically cleared after 5 seconds.
      let isTypingTimeout: ReturnType<typeof setTimeout>;
      const clearIsTyping = () => {
        handler({
          eventType: EventTypes.PARTICIPANT_TYPING,
          eventId: uuidv4(),
          isTyping: false,
        });
      };

      // STATUS CHANGE
      chatSDK.onStatusChange.subscribe((statusChange: StatusChange) => {
        chatInstance.setStatus(statusChange.connectionStatus);
      });
      // CHAT STARTED
      chatSDK.onChatStart.subscribe(() => {
        handler({ eventType: EventTypes.CHAT_STARTED, eventId: uuidv4() });
      });
      // PARTICIPANT JOINED
      chatSDK.onParticipantJoin.subscribe((participant: ParticipantJoin) =>
        handler({
          eventType: EventTypes.PARTICIPANT_JOINED,
          eventId: uuidv4(),
          participant: {
            id: `${participant.participant.id}`,
            name: participant.participant.displayName,
          },
        }),
      );
      // PARTICIPANT LEFT
      chatSDK.onParticipantLeave.subscribe((participant: ParticipantLeave) =>
        handler({
          eventType: EventTypes.PARTICIPANT_LEFT,
          eventId: uuidv4(),
          participant: {
            id: `${participant.participant.id}`,
            name: participant.participant.displayName,
          },
        }),
      );
      chatSDK.onIsTyping.subscribe(() => {
        // If we've already set a timeout to trigger a PARTICIPANT_TYPING event with an isTyping value of false we need to reset the timer.
        if (isTypingTimeout) {
          clearTimeout(isTypingTimeout);
        }
        // We need to set a new timeout to trigger the PARTICIPANT_TYPING event with an isTyping value of false after 5 seconds.
        isTypingTimeout = setTimeout(clearIsTyping, 5000);
        handler({
          eventType: EventTypes.PARTICIPANT_TYPING,
          eventId: uuidv4(),
          isTyping: true,
        });
      });
      // CHAT ENDED
      chatSDK.onChatEnd.subscribe((status: EndChatStatus) => {
        const reason: ChatEndedReason = getChatEndedReason(status);
        handler({
          eventType: EventTypes.CHAT_ENDED,
          eventId: uuidv4(),
          reason,
        });
      });
      // MESSAGE RECEIVED
      chatSDK.onNewMessage.subscribe(
        (participantMessage: ParticipantMessage) => {
          const { messages, participant } = participantMessage;
          if (isTypingTimeout) {
            clearIsTyping();
          }
          // Generates the sender from the participant
          const sender = getMessageSender(participant);
          if (messages.length > 0) {
            // Messages come back as an array of individual messages
            // We need to iterate over them
            messages.forEach((message: MessageType) => {
              // MessageType TEXT
              if (message.messageType === 'TEXT') {
                handler({
                  eventType: EventTypes.MESSAGE,
                  eventId: uuidv4(),
                  message: message.text,
                  sender,
                  timestamp: new Date(),
                });
                return;
              }
              // MessageType URL
              if (
                message.messageType === 'URL' ||
                message.messageType === 'FILE_LINK'
              ) {
                handler({
                  eventType: EventTypes.MESSAGE,
                  eventId: uuidv4(),
                  // Passing in the link value
                  // The FE will manage parsing it
                  message: message.link.value,
                  sender,
                  timestamp: new Date(),
                });
                return;
              }
              // MessageType SUGGESTIONS
              if (message.messageType === 'SUGGESTIONS') {
                const chips = getMessageChips(message.suggestions);
                handler({
                  eventType: EventTypes.MESSAGE,
                  eventId: uuidv4(),
                  chips,
                  sender,
                  timestamp: new Date(),
                });
                return;
              }
              // MessageType CARD
              if (message.messageType === 'CARD') {
                const card = getMessageCard(message.card);
                handler({
                  eventType: EventTypes.MESSAGE,
                  eventId: uuidv4(),
                  card,
                  sender,
                  timestamp: new Date(),
                });
              }
            });
          }
        },
      );
      // MESSAGE ACKNOWLEDGED
      chatSDK.onMessageAcknowledgement.subscribe(
        (acknowledgment: MessageAcknowledgement) => {
          const acknowledgedMessageEventId = chatInstance.acknowledgeMessage(
            acknowledgment.id,
          );
          // If for some reason we're not able to find the eventId for the acknowledged message
          // We don't need to send anything to the UI.
          if (!acknowledgedMessageEventId) {
            return;
          }
          if (acknowledgment.status === 'DELIVERED') {
            if (acknowledgedMessageEventId) {
              handler({
                eventId: uuidv4(),
                eventType: EventTypes.EVENT_ACKNOWLEDGED,
                messageId: acknowledgedMessageEventId,
              });
            }
            return;
          }
          if (acknowledgment.status === 'ERROR') {
            handler({
              eventId: uuidv4(),
              eventType: EventTypes.ERROR_RECEIVED,
              errorType: {
                type: ErrorTypes.MESSAGE_FAILED,
                eventOriginId: acknowledgedMessageEventId,
              },
            });
            return;
          }
          if (acknowledgment.status === 'UNSUPPORTED') {
            handler({
              eventId: uuidv4(),
              eventType: EventTypes.ERROR_RECEIVED,
              errorType: {
                type: ErrorTypes.DEFAULT,
              },
            });
          }
        },
      );
      // ERROR RECEIVED
      chatSDK.onError.subscribe((error: ChatError) => {
        if (error.endChat) {
          return handler({
            eventId: uuidv4(),
            eventType: EventTypes.CHAT_ENDED,
            reason: {
              type: ChatEndedReasonTypes.CONNECTION_LOST,
            },
          });
        }
        return handler({
          eventId: uuidv4(),
          eventType: EventTypes.ERROR_RECEIVED,
          errorType: {
            type: ErrorTypes.DEFAULT,
            ...(error?.message && { message: error.message }),
          },
        });
      });
      chatSDK.onTransferRequest.subscribe(() => {
        handler({
          eventId: uuidv4(),
          eventType: EventTypes.TRANSFER_REQUESTED,
        });
      });
      return null;
    },

    // User Chat Events
    handleUserChatEvents: async (event: UserChatEvents) => {
      let providerId: string | null | undefined;
      let chatStatus: StatusTypes;
      let defaultProvider: string;
      switch (event.eventType) {
        // Start Chat
        case EventTypes.START_CHAT:
          chatInstance.setConfig(event.config);
          defaultProvider = chatInstance.getConfigValue('provider');
          providerId = await checkAvailability(defaultProvider);
          chatStatus = chatInstance.getStatus();
          if (providerId && chatStatus === 'READY') {
            await startChat(providerId);
          }
          break;

        case EventTypes.MESSAGE:
          if (event?.message) {
            const messageId = await sendMessage(event.message);
            // Ensures messageId is not null and that it can be converted to a string
            // Here it is returned as a number
            // In the onMessageAcknowledgement observable it is returned as a string.
            if (messageId?.toString()) {
              chatInstance.addUnacknowledgedMessage(
                messageId.toString(),
                event.eventId,
              );
            }
          }
          if (event?.chips && event.chips.length > 0) {
            await Promise.all(
              event.chips.map(async (chip) => {
                const messageId = await sendChip(chip);
                // Ensures messageId is not null and that it can be converted to a string
                // Here it is returned as a number
                // In the onMessageAcknowledgement observable it is returned as a string.
                if (messageId?.toString()) {
                  chatInstance.addUnacknowledgedMessage(
                    messageId.toString(),
                    event.eventId,
                  );
                }
              }),
            );
          }
          break;
        case EventTypes.PARTICIPANT_TYPING:
          if (event.isTyping) {
            chatSDK.sendIsTyping();
          }
          break;
        case EventTypes.END_CHAT:
          chatSDK.closeChat();
          break;
        default:
      }
    },
  };
};
