export const APP_ID = 'highmark-iel-web';
/**
 * @deprecated TEMPORARY while we transition from client-set TenantID to server-set
 */
export const TENANT_ID = 'highmark-iel';

// Mobile app URLs
export const APP_STORE_PROD_URL =
  'https://apps.apple.com/us/app/my-highmark-app/id1658630454';
export const PLAY_STORE_PROD_URL =
  'https://play.google.com/store/apps/details?id=com.highmark.myhighmark';

// E-Bill service constants
export const E_BILL_SERVICE_NAME = 'E-Bill';
export const USER_REGISTERED_FOR_EBILL_TOKEN = 'Y';
