import { useEffect } from 'react';
import { ENVIRONMENT } from '@leagueplatform/core';

const UAT_BASE_SCRIPT_URL =
  'https://activegateqa.highmark.com:9999/jstag/managed/e1fc6b45-c7e7-4329-82c4-ef4b67d8a94f/';

const PROD_BASE_SCRIPT_URL =
  'https://activegate.highmark.com:9999/jstag/managed/06687b64-fa1f-48ee-903f-4e076414bbbb/';

export const useInjectDynatraceScript = (
  tenantScriptId: string | undefined,
  environment: ENVIRONMENT,
) => {
  useEffect(() => {
    const BASE_SCRIPT_URL =
      environment === ENVIRONMENT.PRODUCTION
        ? PROD_BASE_SCRIPT_URL
        : UAT_BASE_SCRIPT_URL;
    if (tenantScriptId) {
      const dynatraceScript = document.createElement('script');
      dynatraceScript.type = 'text/javascript';
      dynatraceScript.crossOrigin = 'anonymous';
      dynatraceScript.src = `${BASE_SCRIPT_URL}${tenantScriptId}_complete.js`;
      document.head.appendChild(dynatraceScript);
    }
  }, [tenantScriptId, environment]);
};
