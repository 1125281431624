import League, {
  ENVIRONMENT,
  getModulePath,
  LEAGUE_MODULE_NAMES,
  LeagueConfig,
} from '@leagueplatform/core';
import { BrowserRouter, Route, Switch } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  AboutMeRoutes,
  AchievementsRoutes,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  ClaimsRoutes,
  CoordinationOfBenefitsRoutes,
  HealthJourneyRoutes,
  HealthProfileRoutes,
  HomePage,
  LiveChatPage,
  MessagingRoutes,
  SupportPage,
  WalletRoutes,
} from 'components/lazy-routes.component';
import React, { Suspense } from 'react';
import {
  APP_MANAGED_ROUTES,
  AuthSessionContainer,
  AuthSignIn,
  AuthSignOut,
  BasePathRedirect,
  CONTENT_ROUTES,
  DuplicatePathRedirect,
  ERROR_TYPES,
  ErrorState,
  HsaPage,
  LoadingSpinner,
  PageBlockerContainer,
  useGetAuthConfig,
  useInjectDynatraceScript,
} from '@highmark-web/highmark-common';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { useOnlineStatus } from '@leagueplatform/web-common';
import { MASONRY_ROUTES, MasonryPDF } from '@leagueplatform/masonry';
import hsaAccountCreatedImage from 'assets/hsa/hsa-account-created.svg';
import openHsaImage from 'assets/hsa/open-hsa.svg';
import { FormsLibrary } from 'pages/forms-library-routes.page';
import { PrivateRoutesContainer } from './components/private-routes-container.component';
import { initializeNonConfigSDKs } from './config/init-non-config-sdks';
import { BrandedLandingPage } from './pages/branded-landing.page';
import { AboutIel } from './pages/settings/about-iel.page';
import { LegalPageRoutes } from './pages/legal-page-routes.page';
import { IelLanguagePage } from './pages/static/iel-language.page';
import { useGetLeagueConfig } from './config/league-config';
import { STANDALONE_AUTH_CONFIG } from './config/standalone-auth-config';

initializeNonConfigSDKs();

export function App() {
  const { isOnline, isOffline } = useOnlineStatus();
  useInjectDynatraceScript(
    import.meta.env.VITE_DYNATRACE_APP_ID,
    import.meta.env.VITE_ENV as ENVIRONMENT,
  );

  const { isLoading: isAuthLoading, config: authConfig } = useGetAuthConfig(
    STANDALONE_AUTH_CONFIG,
  );
  const config: LeagueConfig = useGetLeagueConfig(authConfig);

  if (isAuthLoading) {
    return <LoadingSpinner />;
  }

  return (
    <League.LeagueProvider config={config}>
      <ObservabilityErrorBoundary
        fallback={<ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />}
        errorContext={{
          context: {
            Context: { location: 'Root App ErrorBoundary' },
          },
        }}
      >
        <Suspense fallback={<LoadingSpinner />}>
          {isOffline && (
            <ErrorState isFullPage errorType={ERROR_TYPES.NO_CONNECTION} />
          )}
          {isOnline && (
            <BrowserRouter>
              <Switch>
                <Route
                  path={[
                    APP_MANAGED_ROUTES.SIGN_IN,
                    APP_MANAGED_ROUTES.SIGN_IN_ALTERNATIVE,
                  ]}
                  component={AuthSignIn}
                />
                <Route
                  path={APP_MANAGED_ROUTES.SIGN_OUT}
                  component={AuthSignOut}
                />
                <Route
                  path={
                    APP_MANAGED_ROUTES.TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES
                  }
                  component={IelLanguagePage}
                />
                <Route path="/" exact component={BrandedLandingPage} />
                <AuthSessionContainer>
                  <PrivateRoutesContainer>
                    {/* Remove duplicate path name if present (needed to support some mobile deeplink configurations in contentful) */}
                    <Route path="/">
                      <DuplicatePathRedirect />
                    </Route>
                    {/* TODO: Remove Temp Redirect routes once all platforms don't use this: https://everlong.atlassian.net/browse/HIGHMARK-2809 */}
                    <Route path={APP_MANAGED_ROUTES.CARE_REDIRECT}>
                      <BasePathRedirect
                        targetBasePath={getModulePath(
                          LEAGUE_MODULE_NAMES.careDiscovery,
                        )}
                      />
                    </Route>
                    <Route path={MASONRY_ROUTES.PDF_GENERATION}>
                      <MasonryPDF loader={<LoadingSpinner />} />
                    </Route>
                    <Route path={APP_MANAGED_ROUTES.WALLET_REDIRECT}>
                      <BasePathRedirect
                        targetBasePath={getModulePath(
                          LEAGUE_MODULE_NAMES.wallet,
                        )}
                      />
                    </Route>
                    <Route path={APP_MANAGED_ROUTES.MEMBER_REDIRECT}>
                      <BasePathRedirect targetBasePath="" />
                    </Route>
                    <Route
                      path={APP_MANAGED_ROUTES.HOME}
                      exact
                      component={HomePage}
                    />
                    <Route path={APP_MANAGED_ROUTES.HSA_ENROLLMENT} exact>
                      <HsaPage
                        openHsaImage={openHsaImage}
                        hsaAccountCreatedImage={hsaAccountCreatedImage}
                      />
                    </Route>
                    <Route
                      path={APP_MANAGED_ROUTES.COORDINATION_OF_BENEFITS}
                      exact
                      component={CoordinationOfBenefitsRoutes}
                    />
                    <Route
                      path={[
                        getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                        getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                        getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
                        getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
                      ]}
                    >
                      <PageBlockerContainer
                        contentRoute={CONTENT_ROUTES.JOURNEY}
                      >
                        <HealthJourneyRoutes />
                      </PageBlockerContainer>
                    </Route>
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
                      component={AboutMeRoutes}
                    />
                    <Route
                      path={getModulePath(
                        LEAGUE_MODULE_NAMES.rewardsAchievements,
                      )}
                      component={AchievementsRoutes}
                    />
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
                      component={WalletRoutes}
                    />
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.claims)}
                      component={ClaimsRoutes}
                    />
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
                      component={HealthProfileRoutes}
                    />
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
                    >
                      <PageBlockerContainer contentRoute={CONTENT_ROUTES.CARE}>
                        <CareCollectionsRoutes />
                      </PageBlockerContainer>
                    </Route>
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                    >
                      <PageBlockerContainer contentRoute={CONTENT_ROUTES.CARE}>
                        <CareDiscoveryRoutes />
                      </PageBlockerContainer>
                    </Route>
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.messaging)}
                      component={MessagingRoutes}
                    />
                    <Route
                      path={APP_MANAGED_ROUTES.SUPPORT}
                      exact
                      component={SupportPage}
                    />
                    <Route
                      path={APP_MANAGED_ROUTES.ABOUT}
                      component={AboutIel}
                    />
                    {/* TODO: PCHAT-3303 Add feature flag for Live Chat route */}
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.liveChat)}
                      component={LiveChatPage}
                    />
                    <Route
                      path={APP_MANAGED_ROUTES.LEGAL}
                      component={LegalPageRoutes}
                    />
                    <Route
                      path={APP_MANAGED_ROUTES.FORMS_LIBRARY}
                      component={FormsLibrary}
                    />
                  </PrivateRoutesContainer>
                </AuthSessionContainer>
              </Switch>
            </BrowserRouter>
          )}
        </Suspense>
        <ReactQueryDevtools />
      </ObservabilityErrorBoundary>
    </League.LeagueProvider>
  );
}
