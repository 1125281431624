import { useIntl } from '@leagueplatform/locales';
import { BodyOne, Box, HeadingTwo } from '@leagueplatform/genesis-commons';
import React from 'react';
import { ModalWrapper } from '../modal-wrapper/modal-wrapper.component';

type LegalModalProps = {
  onClose: () => void;
  isOpen: Boolean;
};

export const LegalModal = ({ onClose, isOpen }: LegalModalProps) => {
  const { formatMessage } = useIntl();

  if (!isOpen) return null;
  return (
    <ModalWrapper
      maxWidth="882px"
      onClose={onClose}
      modalLabel={formatMessage({ id: 'LEGAL_MODAL_TITLE' })}
    >
      <Box marginX="three">
        <HeadingTwo id="modalTitle" marginBottom="oneAndHalf">
          {formatMessage({ id: 'LEGAL_MODAL_TITLE' })}
        </HeadingTwo>
        <BodyOne marginY="oneAndHalf" textAlign="left">
          {formatMessage({ id: 'LEGAL_MODAL_BODY_1' })}
        </BodyOne>
        <BodyOne marginY="oneAndHalf" textAlign="left">
          {formatMessage({ id: 'LEGAL_MODAL_BODY_2' })}
        </BodyOne>
        <BodyOne marginY="oneAndHalf" textAlign="left">
          {formatMessage({ id: 'LEGAL_MODAL_BODY_3' })}
        </BodyOne>
        <BodyOne marginY="oneAndHalf" textAlign="left">
          {formatMessage({ id: 'LEGAL_MODAL_BODY_4' })}
        </BodyOne>
      </Box>
    </ModalWrapper>
  );
};
