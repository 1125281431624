import LinkedinIcon from 'assets/footer/icons/linkedin-icon.svg';
import BloggerIcon from 'assets/footer/icons/blogger-icon.svg';

export const SOCIAL_MEDIA_ICONS = [
  {
    alt: 'LinkedIn',
    src: LinkedinIcon,
    url: 'https://www.linkedin.com/company/highmark/',
  },
  {
    alt: 'Blogger',
    src: BloggerIcon,
    url: 'https://www.highmarkhealth.org/blog/index.shtml',
  },
];
