import { useEffect } from 'react';

const GLIA_VISITOR_URL = 'https://api.glia.com/salemove_integration.js';

// Reference: https://docs.glia.com/glia-dev/docs/visitor-js-sdk
export const useInjectCobrowseScript = (isCobrowseEnabled: boolean) => {
  // inject the Glia script if co-browse is enabled: `<script async src="https://api.glia.com/salemove_integration.js"></script>`
  useEffect(() => {
    if (isCobrowseEnabled) {
      const gliaScript = document.createElement('script');
      gliaScript.async = true;
      gliaScript.src = GLIA_VISITOR_URL;
      document.head.appendChild(gliaScript);
    }
  }, [isCobrowseEnabled]);
};
