import { GDSRem } from '@leagueplatform/genesis-core';

export const rem: GDSRem = {
  baseSize: 16,
  fontSizes: {
    headingOne: 2,
    headingTwo: 1.5,
    headingThree: 1.25,
    headingFour: 1.125,
    subtitleOne: 1,
    subtitleTwo: 0.875,
    bodyOne: 1,
    bodyTwo: 0.875,
    buttonOne: 1,
    buttonTwo: 0.875,
    label: 0.75,
    overline: 0.75,
    caption: 0.75,
    tab: 0.625,
  },
  lineHeights: {
    headingOne: 2.5,
    headingTwo: 2,
    headingThree: 1.75,
    headingFour: 1.5,
    subtitleOne: 1.5,
    subtitleTwo: 1.25,
    bodyOne: 1.5,
    bodyTwo: 1.25,
    buttonOne: 1.5,
    buttonTwo: 1.25,
    label: 1,
    overline: 1,
    caption: 1,
    tab: 0.75,
  },
};
