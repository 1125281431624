import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { Location, useHistory, useLocation } from '@leagueplatform/routing';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { CS_AUTH0_CLIENT_CONNECTION } from 'common/customer-support-constants';
import { logger } from 'utils/logger';
import { ErrorState } from 'components/error/error-state.component';
import { ERROR_TYPES } from 'hooks/use-error-message/use-error-message';
import { captureAuthenticationError } from 'utils/sentry-helpers';

// Gets auth0 error returned from redirect, the returned error could be a query param or a hash param
const getAuth0ErrorIfExists = (location: Location) => {
  const searchParams = new URLSearchParams(location.search);
  const hashParams = new URLSearchParams(location.hash.replace(/#/g, '?'));

  const type = searchParams.get('error') || hashParams.get('error');
  const description =
    searchParams.get('error_description') ||
    hashParams.get('error_description');

  if (type) {
    return { type, description };
  }

  return null;
};

export const CSAuthSignIn = () => {
  const location = useLocation();
  const history = useHistory();

  const [error, setError] = useState('');

  useEffect(() => {
    const connection =
      new URLSearchParams(location.search).get('connection') ||
      CS_AUTH0_CLIENT_CONNECTION;

    const authError = getAuth0ErrorIfExists(location);
    if (authError) {
      setError(`${authError.type}: ${authError.description || ''}`);
      captureAuthenticationError(authError);
      return;
    }

    try {
      const options = {
        connection,
      };

      StandaloneAuth.client.loginWithRedirect(options);
    } catch (e: unknown) {
      logger.error(e);
      setError(e instanceof Error ? e.message : String(e));
    }
  }, [location, history]);

  if (error) {
    return (
      <ErrorState
        isFullPage
        errorType={ERROR_TYPES.API_ERROR}
        bodyString={error}
        hideButton
      />
    );
  }
  return <LoadingSpinner />;
};
