import React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import {
  AssetLink,
  AssetLinkProps,
} from 'components/asset-links/asset-link.component';
import {
  FooterLinkItems,
  FooterLinkProps,
} from './footer-link-items.component';

const FooterLinksLayoutWrapper = ({ children }: any) => (
  <StackLayout
    orientation={{
      '@initial': 'vertical',
      '@laptop': 'horizontal',
      '@desktop': 'horizontal',
    }}
    horizontalAlignment="center"
    verticalAlignment={{
      '@initial': 'top',
      '@laptop': 'spaceBetween',
      '@desktop': 'spaceBetween',
    }}
    css={{
      marginBottom: '$half',
      flexWrap: 'wrap',
      '@laptop': {
        flexWrap: 'nowrap',
      },
      '@desktop': {
        flexWrap: 'nowrap',
      },
    }}
  >
    {children}
  </StackLayout>
);

export const FooterLinksAndIconsSection = ({
  footerLinks,
  footerIcons,
}: {
  footerLinks: FooterLinkProps[];
  footerIcons?: AssetLinkProps[];
}) => (
  <FooterLinksLayoutWrapper>
    {/* Footer Links Section */}
    <StackLayout
      orientation={{
        '@initial': 'vertical',
        '@laptop': 'horizontal',
        '@desktop': 'horizontal',
      }}
      horizontalAlignment={{
        '@initial': 'center',
        '@laptop': 'start',
        '@desktop': 'start',
      }}
      css={{
        marginBottom: '$one',
        flexWrap: 'wrap',
        flexBasis: 'auto',
      }}
    >
      <FooterLinkItems
        linkItems={footerLinks}
        styleProps={{
          display: 'flex',
          marginRight: 'none',
          marginLeft: 'none',
          marginBottom: '$one',
          justifyContent: 'center',
          '@laptop': {
            marginRight: '$oneAndHalf',
            marginBottom: 'none',
            justifyContent: 'start',
          },
        }}
      />
    </StackLayout>

    {/* Footer Icons Section */}
    {!!footerIcons && (
      <StackLayout
        css={{
          flexWrap: 'wrap',
          alignItems: 'center',
          flexBasis: 'auto',
          '@laptop': {
            flexWrap: 'nowrap',
          },
          '@desktop': {
            flexWrap: 'nowrap',
          },
        }}
        orientation="horizontal"
        horizontalAlignment={{
          '@initial': 'center',
          '@laptop': 'end',
          '@desktop': 'end',
        }}
      >
        {footerIcons.map((item) => (
          <AssetLink
            key={item.url}
            src={item.src}
            alt={item.alt}
            url={item.url}
            styleProps={{
              marginBottom: 'two',
              marginX: { _: 'one', laptop: 'half' },
              width: { _: 'twoAndHalf', laptop: 'oneAndHalf' },
            }}
          />
        ))}
      </StackLayout>
    )}
  </FooterLinksLayoutWrapper>
);
