import * as React from 'react';
import {
  AppBarComponent,
  AppBarItem,
  DropdownSection,
} from '@leagueplatform/web-common-components';
import { Image } from '@leagueplatform/genesis-commons';
import { Box } from '@leagueplatform/genesis-core';
import { APP_NAV_DEFAULT_HEIGHT } from 'common/constants';
import { NavLink } from 'hooks/use-nav-links-list.hook';
import { BrandingInfo } from 'hooks/use-get-app-init-details';
import { NavLinkWrapper } from './nav-link-wrapper.component';
import { ResponsiveUserDropdown } from './responsive-user-dropdown.component';

export const MobileNavbar: React.FC<{
  userDropdownConfig: DropdownSection[];
  navLinks: NavLink[];
  brandingInfo?: BrandingInfo;
}> = ({ userDropdownConfig, navLinks, brandingInfo }) => (
  <>
    {/* Top navigation bar in mobile mode (logo & dropdown) */}
    <Box
      css={{
        display: 'flex',
        padding: '$threeQuarters',
        justifyContent: 'end',
      }}
    >
      {brandingInfo?.brandingLogo && (
        <AppBarItem margin="auto">
          <Image
            height="oneAndHalf"
            role="presentation"
            alt="provider-logo"
            src={brandingInfo.brandingLogo}
          />
        </AppBarItem>
      )}

      {/* App Nav User Dropdown */}
      <ResponsiveUserDropdown userDropdownConfig={userDropdownConfig} />
    </Box>

    {/* NavLinks */}
    <AppBarComponent navHeight={APP_NAV_DEFAULT_HEIGHT}>
      {navLinks.map((navLink) => (
        <NavLinkWrapper key={navLink.message_id} navLink={navLink} />
      ))}
    </AppBarComponent>
  </>
);
