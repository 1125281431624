import { GDSBorderRadii } from '@leagueplatform/genesis-core';

export const borderRadii: GDSBorderRadii = {
  none: 0,
  thin: 1,
  thick: 2,
  small: 3,
  medium: 6,
  large: 9,
  extraLarge: 12,
  huge: 20,
  pill: 99999,
  circle: 99999,
  button: 40,
  inputField: 6,
};
