import { useMutation } from 'react-query';
import { acceptUserTerms } from '../api/accept-user-terms';

export const useAcceptUserTerms = () => {
  const acceptTerms = useMutation(() => acceptUserTerms());
  const ERROR_TEXT: string = 'error accepting terms';

  return async () => {
    try {
      const response = await acceptTerms.mutateAsync();
      return { success: Boolean(response.date_accepted) };
    } catch (error) {
      throw new Error(ERROR_TEXT);
    }
  };
};
