import { GDSColors } from '@leagueplatform/genesis-core';

// import themeable tokens
import { buttonTokens } from './tokens/light/buttonTokens';
import { surfaceTokens } from './tokens/light/surfaceTokens';
import { interactiveTokens } from './tokens/light/interactiveTokens';
import { statusTokens } from './tokens/light/statusTokens';
import { decorativeTokens } from './tokens/light/decorativeTokens';
import { inputTokens } from './tokens/light/inputTokens';

export const colors: GDSColors = {
  ...surfaceTokens,
  ...buttonTokens,
  ...interactiveTokens,
  ...inputTokens,
  ...statusTokens,
  ...decorativeTokens,
};
