import { useEffect, useState } from 'react';
import { useUrlParam } from '@leagueplatform/web-common';
import {
  QUERY_PARAM_KEYS,
  SSO_READY_LISTENER_TIMEOUT,
  SSO_URL_NAMES,
  WEBVIEW_MODAL_QUERY_PARAM_VALUE,
} from 'common/sso-constants';
import { LinkHandler, LinkHandlerClass } from 'utils/link-handler';
import { sendAuthTokenIfReady } from 'utils/post-message-auth-helpers';
import { logger } from 'utils/logger';
import { captureAuthedWebviewError } from 'utils/sentry-helpers';
import { getSsoUrl, getTrustedPostMessageOrigins } from 'utils/sso-url-helpers';

export const useAuthedWebviewModal = (
  iframeRef: React.RefObject<HTMLIFrameElement> | null,
  linkHandler: LinkHandlerClass,
) => {
  const [url, setUrl] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { removeParam, urlParam } = useUrlParam(QUERY_PARAM_KEYS.modal);

  const onError = (caughtError: Error) => {
    setError(caughtError);
    captureAuthedWebviewError(caughtError, url);
  };

  const closeModal = () => {
    removeParam();
    setUrl('');
    setError(null);
  };

  useEffect(() => {
    if (urlParam === WEBVIEW_MODAL_QUERY_PARAM_VALUE) {
      const newUrl = linkHandler.lastPassedUrl;
      if (!newUrl) {
        removeParam();
      } else {
        setUrl(newUrl);
      }
    }
  }, [urlParam, removeParam, linkHandler]);

  useEffect(() => {
    if (url && iframeRef?.current) {
      const targetOrigin = new URL(url).origin;
      const messageHandler = async (event: MessageEvent) => {
        logger.log('Received postmessage event', event);

        try {
          const isAuthenticated = await sendAuthTokenIfReady(
            event,
            targetOrigin,
            iframeRef?.current?.contentWindow,
          );

          if (isAuthenticated) {
            setLoading(false);
          }
        } catch (err: any) {
          setLoading(false);
          onError(err instanceof Error ? err : new Error(err));
        }

        const action = event?.data?.action;

        const navigationTarget = event?.data?.navigate_to;

        // If event is not coming from a whitelisted origin or the src origin, ignore.
        if (
          event?.origin !== targetOrigin &&
          !getTrustedPostMessageOrigins().includes(event?.origin)
        ) {
          return;
        }

        if (navigationTarget) {
          switch (navigationTarget) {
            case 'sapphire':
              LinkHandler.handleLink(getSsoUrl(SSO_URL_NAMES.SAPPHIRE));
              closeModal();
              break;

            default:
              captureAuthedWebviewError(
                new Error(
                  `Unknown navigation target received: ${navigationTarget}: ${JSON.stringify(
                    event.data,
                  )}`,
                ),
                url,
              );
          }
        }

        if (action) {
          switch (action) {
            case 'close':
              closeModal();
              break;

            default:
              captureAuthedWebviewError(
                new Error(
                  `Unknown action received: ${action}: ${JSON.stringify(
                    event.data,
                  )}`,
                ),
                url,
              );
          }
        }
      };

      window.addEventListener('message', messageHandler);

      const timeout = setTimeout(() => {
        // If isLoading is still true, this means ready message wasn't received.
        // Then, set loading to false and set error state
        setLoading((loading) => {
          if (loading) {
            onError(
              new Error(
                'Could not send auth token, ready message not received from iframe.',
              ),
            );
          }
          return false;
        });
      }, SSO_READY_LISTENER_TIMEOUT);

      return () => {
        clearTimeout(timeout);
        window.removeEventListener('message', messageHandler);
      };
    }
    setLoading(true);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeRef, urlParam, url]);

  return {
    isOpen: Boolean(urlParam && url),
    url,
    closeModal,
    isLoading,
    error,
  };
};
