import { FirebaseRemoteConfigValue } from 'common/constants';
import { FirebaseApp } from 'firebase/app';
import { getRemoteConfig, RemoteConfig } from 'firebase/remote-config';

type InitFirebaseRemoteConfigProps = {
  firebaseApp: FirebaseApp;
  defaultValues: { [key: string]: FirebaseRemoteConfigValue };
  minimumFetchIntervalMillis?: number; // should only be used for development/debugging
};

export const initFirebaseRemoteConfig = ({
  firebaseApp,
  defaultValues,
  minimumFetchIntervalMillis,
}: InitFirebaseRemoteConfigProps): RemoteConfig => {
  // Initialize Remote Config and get a reference to the service
  const remoteConfig: RemoteConfig = getRemoteConfig(firebaseApp);

  // Set default fallback values in case of network errors
  remoteConfig.defaultConfig = defaultValues;

  /* In production by default Firebase throttles fetch requests to once per 12 hours (per session)
   * In development this can be overridden to allow for more frequent fetch requests
   */
  if (minimumFetchIntervalMillis) {
    remoteConfig.settings.minimumFetchIntervalMillis =
      minimumFetchIntervalMillis;
  }

  return remoteConfig;
};
