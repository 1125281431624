export interface HighmarkCommonConfig {
  highmarkContentUrl?: string;
  highmarkMemberPortalUrl?: string;
  highmarkSsoAppUrl?: string;
  highmarkRestApiUrl?: string;
}

let highmarkCommonConfig: HighmarkCommonConfig = {};

export const highmarkCommon = {
  config(config: HighmarkCommonConfig) {
    highmarkCommonConfig = { ...highmarkCommonConfig, ...config };
  },

  getConfig() {
    if (!highmarkCommonConfig)
      throw new Error(
        'Highmark Common: Configuration is not defined or initialized.',
      );
    return highmarkCommonConfig;
  },
};
