import React from 'react';
import {
  Box,
  QuietButton,
  VisuallyHidden,
  Flex,
  SubtitleOne,
  Image,
} from '@leagueplatform/genesis-commons';
import { Modal, useModalA11yProps } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import CLOSE_ICON from 'assets/close.svg';

const DEFAULT_HEADER_HEIGHT = '50px';

const modalTitleId = 'iframe-modal-title';

interface ModalAriaPropsTypes {
  'aria-label': string;
  'aria-modal': string;
  role: string;
}

type IframeModalProps = {
  onClose?: () => void;
  children: React.ReactNode;
  title?: string;
  headerHeight?: string;
};

export const IframeModal = ({
  onClose,
  children,
  headerHeight = DEFAULT_HEADER_HEIGHT,
  title,
}: IframeModalProps) => {
  const { formatMessage } = useIntl();

  const {
    'aria-label': ariaLabel,
    'aria-modal': ariaModal,
    role,
  } = useModalA11yProps(title) as ModalAriaPropsTypes;

  return (
    <Modal.Wrapper options={{}} onClose={onClose}>
      <Box
        position="relative"
        width="85vw"
        borderRadius="medium"
        overflow="hidden"
        height="calc(100vh - 96px)"
        aria-labelledby={modalTitleId}
      >
        {/* Modal Header Component */}
        <Flex
          height={headerHeight}
          justifyContent="flex-end"
          backgroundColor="surface.background.primary"
        >
          {title && (
            <SubtitleOne id={modalTitleId} margin="auto">
              {title}
            </SubtitleOne>
          )}
          {onClose && (
            <QuietButton
              lineHeight="caption"
              top="half"
              right="one"
              paddingX="half"
              paddingY="half"
              onClick={onClose}
              position="absolute"
            >
              <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
              <Image
                src={CLOSE_ICON}
                role="presentation"
                type="button"
                aria-hidden="true"
                stroke="interactive.action.primary"
              />
            </QuietButton>
          )}
        </Flex>
        {/* Modal Body */}
        <Box
          tabIndex="0"
          height={`calc(100% - ${headerHeight})`}
          width="100%"
          backgroundColor="surface.background.primary"
          aria-label={ariaLabel}
          aria-modal={ariaModal}
          role={role}
        >
          {children}
        </Box>
      </Box>
    </Modal.Wrapper>
  );
};
