import {
  APP_MANAGED_ROUTES,
  FEATURE_FLAGS,
} from '@highmark-web/highmark-common';
import { firebaseRemoteConfig } from 'common/firebase/firebase';
import { getBoolean } from 'firebase/remote-config';

export function getClaimsConfig() {
  return {
    showTotalCostOverviewAndAdditionalResources: false,
    supportLink: APP_MANAGED_ROUTES.SUPPORT,
    featureFlags: {
      shouldShowClaimDetails: getBoolean(
        firebaseRemoteConfig,
        FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_CLAIM_DETAILS,
      ),
      shouldShowDownloadClaims: getBoolean(
        firebaseRemoteConfig,
        FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_DOWNLOAD_CLAIMS,
      ),
      shouldShowFilterChips: getBoolean(
        firebaseRemoteConfig,
        FEATURE_FLAGS.CLAIMS_SHOULD_SHOW_FILTER_CHIPS,
      ),
    },
  };
}
