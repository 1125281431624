import { useIntl } from '@leagueplatform/locales';
import React, { useEffect, useState } from 'react';
import { useGetHsaRegistrationEligibility } from 'hooks/use-get-hsa-registration-eligibility.hook';
import { BannerWidget } from 'components/widget/banner-widget.component';
import piggyBank from 'assets/icons/saving-piggy-heart.svg';

export const HsaPendingBannerWidget = () => {
  const { formatMessage } = useIntl();
  const [showBanner, setShowBanner] = useState(false);
  const { data, isLoading: isHsaRegisteredLoading } =
    useGetHsaRegistrationEligibility();

  useEffect(() => {
    setShowBanner(!isHsaRegisteredLoading && data?.status === 'Pending');
  }, [isHsaRegisteredLoading, data?.status]);

  return showBanner ? (
    <BannerWidget
      heading={formatMessage({ id: 'HSA_PENDING_BANNER_TITLE' })}
      body={formatMessage({ id: 'HSA_PENDING_BANNER_BODY' })}
      image={piggyBank}
      imageAltText="piggybank"
    />
  ) : null;
};
