import * as React from 'react';
import { Modal } from '@leagueplatform/ui-kit';
import {
  css,
  genesisStyled,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { Button, Icon, StackLayout, Box } from '@leagueplatform/genesis-core';

const StyledModalContainer = genesisStyled(Modal.Container)(
  css({
    position: 'relative',
    borderRadius: 'medium',
    backgroundColor: 'surface.background.primary',
    padding: 'oneAndHalf',
    paddingTop: 'four',
  }),
);

type ModalWrapperProps = {
  onClose: () => void;
  modalLabel: String;
  children: React.ReactChild;
  confirmButtonText?: String;
  onConfirmClick?: () => void;
  maxWidth?: string;
};

export const ModalWrapper = ({
  onClose,
  modalLabel,
  children,
  confirmButtonText,
  onConfirmClick = () => {
    onClose();
  },
  maxWidth = '380px',
}: ModalWrapperProps) => {
  const { formatMessage } = useIntl();
  return (
    <Modal.Wrapper options={{}} onClose={onClose}>
      <StyledModalContainer maxWidth={maxWidth} aria-label={modalLabel}>
        <Box css={{ position: 'absolute', right: '0', top: '0' }}>
          <Button
            onClick={onClose}
            css={{
              padding: '$half',
              margin: '$one',
            }}
            priority="secondary"
            quiet
          >
            <StackLayout>
              <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
              <Icon icon="tinyClose" size={16} aria-hidden="true" />
            </StackLayout>
          </Button>
        </Box>
        <Box>
          {children}
          {confirmButtonText && (
            <Button
              priority="primary"
              onClick={onConfirmClick}
              css={{ marginTop: '$five' }}
            >
              {confirmButtonText}
            </Button>
          )}
        </Box>
      </StyledModalContainer>
    </Modal.Wrapper>
  );
};
