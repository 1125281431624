import { leagueFetch } from '@leagueplatform/league-fetch';
// @ts-ignore
import { deserialise } from 'kitsu-core';
// Kitsu doesn't seem to have typings setup, https://github.com/wopian/kitsu/issues/967
// This is still WIP, ignoring for now: https://github.com/wopian/kitsu/pull/818

const CONTENT_MAPPING_PATH = '/v1/content-mapping';

export const getContentMapping = async (contentId: string) => {
  const response = await leagueFetch(`${CONTENT_MAPPING_PATH}/${contentId}`);
  if (!response?.ok) {
    throw new Error(
      `Error Response Code ${response?.status}: ${await response.text()}`,
    );
  }
  return deserialise(await response.json());
};
