import { useGetHighmarkUserProfile } from './use-get-user-profile';

export const usePreferredFirstName = () => {
  const { data, isLoading, error } = useGetHighmarkUserProfile();
  const preferredFirstName =
    data?.userProfile?.preferredFirstName ??
    data?.userProfile?.firstName ??
    data?.email ??
    '';

  return { data: { preferredFirstName }, isLoading, error };
};
