import React from 'react';
import { PageIneligibility } from 'api/get-app-init-details';

export const PageEligibilityContext = React.createContext<PageIneligibility>({
  home: undefined,
  benefits: undefined,
  getCare: undefined,
  journey: undefined,
  support: undefined,
});
