import {
  CareProvider,
  CareProvidersData,
  getCareProviders,
} from '@leagueplatform/care-discovery-api';
import { useQuery } from 'react-query';

// TODO: Remove hardcoded benefit id once api for this benefit is available https://everlong.atlassian.net/browse/HMRK-720
export enum BENEFIT_IDS {
  CALL_MEMBER_SERVICES = '771dad3f2bd2b5837ccede93df321240',
  AMWELL_WELL360 = '4843416d77656c6c0000000000000000',
}

const benefitIdEnabled = (
  item: CareProvider,
  targetBenefits: BENEFIT_IDS[],
) => {
  let enabled = false;

  targetBenefits.forEach((benefitId) => {
    enabled = enabled || item?.attributes?.url?.includes(benefitId);
  });

  return enabled;
};

export const useGetAssistance = (targetBenefits: BENEFIT_IDS[]) => {
  const { data, isLoading, isError } = useQuery<CareProvidersData, Error>(
    ['GET_CARE_PROVIDERS'],
    () => getCareProviders(),
  );

  // We only want specific benefit(s) to show up on homescreen
  const memberServicesBenefit = data?.data
    ? data.data.filter((item) => benefitIdEnabled(item, targetBenefits))
    : [];

  return { data: memberServicesBenefit, isLoading, isError };
};
