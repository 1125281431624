/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Flex, FlexProps } from '@leagueplatform/genesis-commons';
import {
  ERROR_TYPES,
  useErrorMessage,
} from 'hooks/use-error-message/use-error-message';
import { FullScreenContainer } from 'components/full-screen-container.component';
import { ErrorState as WebCommonErrorState } from '@leagueplatform/web-common-components';

// Using explicit props instead of extending ErrorMessageProps due to IntrinsicAttributes error
export type ErrorStateProps = {
  isFullPage?: boolean;
  styleProps?: FlexProps;
  errorType?: ERROR_TYPES;
  hideButton?: boolean;
  headingString?: string;
  bodyString?: string;
  buttonId?: string;
  buttonAction?: () => void;
};

export const ErrorState = ({
  styleProps,
  isFullPage = false,
  hideButton = false,
  ...errorData
}: ErrorStateProps) => {
  const errorContent = useErrorMessage(errorData);

  if (isFullPage) {
    return (
      <FullScreenContainer backgroundColor="surface.background.primary">
        <Flex
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
          maxWidth="350px"
          {...styleProps}
        >
          <WebCommonErrorState hideButton={hideButton} {...errorContent} />
        </Flex>
      </FullScreenContainer>
    );
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      {...styleProps}
    >
      <WebCommonErrorState hideButton={hideButton} {...errorContent} />
    </Flex>
  );
};
