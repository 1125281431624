import { highmarkFetch } from '../utils/highmark-fetch';

const HSA_ELIGIBILITY_URL = '/v1/members/spending/hsa/eligible';

export enum HSA_ELIGIBILITY_STATUS {
  ELIGIBLE = 'Eligible',
  PENDING = 'Pending',
}

interface HsaClient {
  clientNumber: string;
  clientName: string;
  groupNumber: string;
  memberId: string;
  planName: string;
  status: HSA_ELIGIBILITY_STATUS;
  isRegistered: boolean;
}

export interface HsaEligibility {
  clients: HsaClient[];
  memberId: string;
}

export const getHsaEligibility = async (
  partnerAccessToken: String,
): Promise<HsaEligibility> => {
  const response = await highmarkFetch(HSA_ELIGIBILITY_URL, partnerAccessToken);
  return response.json();
};
