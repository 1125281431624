import { useEffect, useState } from 'react';
import { useHistory } from '@leagueplatform/routing';
import {
  AUTHED_WEBVIEW_URL_NAMES,
  SSO_READY_LISTENER_TIMEOUT,
} from 'common/sso-constants';
import { captureAuthedWebviewError } from 'utils/sentry-helpers';
import { sendAuthTokenIfReady } from 'utils/post-message-auth-helpers';
import { logger } from 'utils/logger';
import { getAuthedWebviewUrl } from 'utils/sso-url-helpers';

export const useMfaEnrollmentModal = (
  iframeRef: React.RefObject<HTMLIFrameElement> | null,
  onEnrollSuccess: () => void,
) => {
  const mfaUrl = getAuthedWebviewUrl(AUTHED_WEBVIEW_URL_NAMES.MFA_ENROLLMENT);
  const { origin } = new URL(mfaUrl);

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const history = useHistory();

  const onError = (caughtError: Error) => {
    setError(caughtError);
    captureAuthedWebviewError(caughtError, mfaUrl);
  };

  useEffect(() => {
    if (iframeRef?.current) {
      const messageHandler = async (event: MessageEvent) => {
        logger.log('Received message', event);

        try {
          const isAuthenticated = await sendAuthTokenIfReady(
            event,
            origin,
            iframeRef?.current?.contentWindow,
          );
          if (isAuthenticated) {
            setLoading(false);
          }
        } catch (err: any) {
          if (err instanceof Error) {
            onError(err instanceof Error ? err : new Error(err));
          }
          setLoading(false);
        }

        const status = event?.data?.status;
        if (event?.origin !== origin || !status) {
          return;
        }

        switch (status) {
          case 'complete':
            onEnrollSuccess();
            break;
          case 'cancelled':
            history.push('/sign-out');
            break;
          case 'error':
            // Don't show error on our end for now, it should be handled by webview
            captureAuthedWebviewError(
              new Error(
                event.data?.description || 'An error message has been received',
              ),
              mfaUrl,
            );
            break;
          default:
            // Don't block webview flow by erroring out, but report it
            captureAuthedWebviewError(
              new Error(
                `Unknown status received: ${status}: ${JSON.stringify(
                  event.data,
                )}`,
              ),
              mfaUrl,
            );
        }
      };

      window.addEventListener('message', messageHandler);

      const loadingTimeout = setTimeout(() => {
        // If isLoading is still true, this means ready message wasn't received.
        // Then, set loading to false and set error state
        setLoading((loading) => {
          if (loading) {
            onError(
              new Error(
                'Could not send auth token, ready message not received from iframe.',
              ),
            );
          }
          return false;
        });
      }, SSO_READY_LISTENER_TIMEOUT);

      return () => {
        clearTimeout(loadingTimeout);
        window.removeEventListener('message', messageHandler);
      };
    }
    setLoading(true);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeRef, onEnrollSuccess]);

  return {
    url: mfaUrl,
    isLoading,
    error,
  };
};
