import React from 'react';
import { useLocation, Redirect } from '@leagueplatform/routing';

export const BasePathRedirect = ({
  targetBasePath,
}: {
  targetBasePath: string;
}) => {
  const location = useLocation();
  return (
    <Redirect
      to={{
        ...location,
        // Replaces first path string before non character value (/, ?, etc) with new path, e.g. /old-path/same -> /new-path/same
        pathname: location.pathname.replace(/^\/[\w-]+/, targetBasePath),
      }}
    />
  );
};
