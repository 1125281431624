import React from 'react';
import {
  HeadingText,
  StackItem,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { PageHeaderMobile } from 'components/page-header-mobile/page-header-mobile.component';
import {
  ErrorState,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { HtmlToReact } from '@leagueplatform/web-common';
import { useLegalContent } from './use-legal-content.hook';

export const LegalContent = () => {
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));

  const { data, isLoading, isError } = useLegalContent();

  if (isLoading || isError) {
    return (
      <StackLayout>
        <PageHeaderMobile showBackButton={isMobile} />
        <StackLayout
          horizontalAlignment="center"
          css={{ minHeight: '50vh', width: '100%', position: 'relative' }}
        >
          {isLoading && <LoadingIndicator />}
          {isError && <ErrorState />}
        </StackLayout>
      </StackLayout>
    );
  }

  if (isMobile) {
    return (
      <StackLayout>
        <PageHeaderMobile headingText={data?.title} />
        <StackItem css={{ marginTop: '$two' }}>
          <HtmlToReact htmlString={data?.body} />
        </StackItem>
      </StackLayout>
    );
  }

  return (
    <StackLayout>
      <PageHeaderMobile showBackButton={false} />
      <StackLayout
        spacing="$one"
        css={{
          '@desktop': {
            marginTop: '$half',
          },
        }}
      >
        <HeadingText level="1" size={{ '@initial': 'xxl', '@mobile': 'xl' }}>
          {data?.title}
        </HeadingText>
        <StackItem
          css={{
            marginY: '$one',
            '@desktop': {
              paddingRight: '$two',
            },
          }}
        >
          <HtmlToReact htmlString={data?.body} />
        </StackItem>
      </StackLayout>
    </StackLayout>
  );
};
