import { useQuery } from 'react-query';
import { getPartnerAccessToken } from '@leagueplatform/sso-util';

export const useGetIdentityPartnerToken = (partnerId: string) =>
  useQuery<any, Error>(
    ['GET_IDENTITY_PARTNER_TOKEN'],
    () => getPartnerAccessToken(partnerId),
    {
      cacheTime: 2700000, // Setting cache timer for 45 minutes to fetch new identity token
    },
  );
