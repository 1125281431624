import { getPartnerAccessToken } from '@leagueplatform/sso-util';

export class IdentityToken {
  private token: string | null = null;

  private partnerId: string;

  private tokenRefresh: NodeJS.Timeout | number | null = null;

  constructor(partnerId: string) {
    this.token = null;
    this.partnerId = partnerId;
    this.tokenRefresh = null;
  }

  async fetchToken() {
    try {
      const newToken = await getPartnerAccessToken(this.partnerId);
      if (newToken) {
        this.token = newToken;
      }
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async keepTokenFresh() {
    await this.fetchToken();
    // In case page renders more than once
    // This ensures the timeout is only ever running once
    if (this.tokenRefresh) {
      clearTimeout(this.tokenRefresh);
    }
    // Setting the refresh period to 45 minutes to align with
    // the useGetIdentityPartnerToken hook.
    this.tokenRefresh = setTimeout(this.keepTokenFresh.bind(this), 2700000);
  }

  async getToken() {
    if (this.token) {
      return this.token;
    }
    // Should only fire the when the page initially loads.
    await this.keepTokenFresh();
    return this.token;
  }
}
