import {
  Box,
  Checkbox,
  ChoiceText,
  Label,
  SpaceProps,
} from '@leagueplatform/genesis-commons';
import { Field } from 'formik';
import React from 'react';

type FormikCheckboxProps = {
  fieldId: string;
  fieldName: string;
  fieldBody: string | React.ReactNode;
  formErrors: { [key: string]: any };
  spaceProps?: SpaceProps;
};

export const FormikCheckBox = ({
  fieldId,
  fieldName,
  fieldBody,
  formErrors,
  spaceProps,
}: FormikCheckboxProps) => (
  <Label
    htmlFor={fieldId}
    display="flex"
    alignItems="flex-start"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...spaceProps}
  >
    <Box>
      <Field
        id={fieldId}
        name={fieldName}
        as={Checkbox}
        type="checkbox"
        marginRight="half"
        error={formErrors[fieldName]}
      />
    </Box>
    <ChoiceText>{fieldBody}</ChoiceText>
  </Label>
);
