import { useUrlParam } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Button,
  HeadingText,
  ImageMessage,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import React from 'react';
import {
  MODAL_PARAMS,
  useQueryModalContent,
  VALID_MODAL_PARAMS,
} from 'hooks/use-query-modal-content.hook';
import {
  QUERY_PARAM_KEYS,
  WEBVIEW_MODAL_QUERY_PARAM_VALUE,
} from 'common/sso-constants';
import { ModalWrapper } from 'components/modal-wrapper/modal-wrapper.component';

export const QueryParamModal = () => {
  const { formatMessage } = useIntl();
  const { removeParam, urlParam } = useUrlParam(QUERY_PARAM_KEYS.modal);
  const {
    label,
    image,
    heading,
    body,
    bodyTwo,
    cancelButton,
    submitButton,
    styling,
  } = useQueryModalContent(urlParam as MODAL_PARAMS);

  if (
    !VALID_MODAL_PARAMS.includes(urlParam as MODAL_PARAMS) ||
    !label ||
    !heading ||
    !body
  ) {
    // remove the modal param unless it is for the authed webview
    if (urlParam !== WEBVIEW_MODAL_QUERY_PARAM_VALUE) {
      removeParam();
    }
    return null;
  }

  return (
    <ModalWrapper
      onClose={removeParam}
      modalLabel={formatMessage({ id: label })}
      maxWidth="890px"
    >
      <StackLayout
        spacing="$one"
        css={{
          padding: '$one $three',
          '@mobile': { padding: '0' },
          '@mobileLandscape': { padding: '$one' },
          ...styling,
        }}
      >
        {image && (
          <StackLayout css={{ width: '100%' }} horizontalAlignment="center">
            <ImageMessage image={image} imageHeight={160} />
          </StackLayout>
        )}
        <Box>
          <HeadingText
            size="xl"
            level="1"
            css={{ marginBottom: '$oneAndHalf' }}
          >
            {formatMessage({ id: heading })}
          </HeadingText>
          <ParagraphText
            emphasis="subtle"
            css={{ marginBottom: '$oneAndHalf' }}
          >
            {formatMessage({ id: body })}
          </ParagraphText>
          {bodyTwo && (
            <ParagraphText emphasis="subtle">
              {formatMessage({ id: bodyTwo })}
            </ParagraphText>
          )}
        </Box>

        <StackLayout
          orientation="horizontal"
          horizontalAlignment={{ '@initial': 'end', '@mobile': 'spaceBetween' }}
          spacing="$one"
          css={{ width: '100%' }}
        >
          {cancelButton && (
            <Button
              onClick={cancelButton.onClick || removeParam}
              priority="secondary"
              quiet
            >
              {formatMessage({ id: cancelButton.id })}
            </Button>
          )}
          {submitButton && (
            <Button onClick={submitButton.onClick || removeParam}>
              {formatMessage({ id: submitButton.id })}
            </Button>
          )}
        </StackLayout>
      </StackLayout>
    </ModalWrapper>
  );
};
