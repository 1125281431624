import React from 'react';
import {
  BodyOne,
  Box,
  GenesisTheme,
  HeadingThree,
  HeadingTwo,
  PrimaryButton,
  useTheme,
} from '@leagueplatform/genesis-commons';
import { CardBanner, StackLayout } from '@leagueplatform/web-common-components';

const getCardBannerStyles = ({ breakpoints }: GenesisTheme) => ({
  imageContainer: {
    alignSelf: 'center',
    minHeight: '128px',
    width: '128px',
    flexShrink: '0',
    margin: 'two',
    marginBottom: 'none',
    '&&': {
      [`@media screen and (min-width: ${breakpoints.phone})`]: {
        marginBottom: 'two',
        height: '128px',
        maxWidth: '128px',
        borderLeftStyle: 'none',
      },
    },
  },
});

export const HomeCardComponent = ({
  heading,
  subheading,
  body,
  image = '',
  ctaText,
  onButtonClick,
}: {
  heading: string;
  subheading: string;
  body: string;
  image: string;
  ctaText: string;
  onButtonClick?: () => void;
}) => {
  const theme = useTheme();
  return (
    <Box>
      <HeadingTwo marginBottom="oneAndHalf">{heading}</HeadingTwo>
      <CardBanner image={image || ''} styles={getCardBannerStyles(theme)}>
        <StackLayout space="$half">
          <HeadingThree>{subheading}</HeadingThree>
          <BodyOne color="onSurface.text.subdued">{body}</BodyOne>
        </StackLayout>
        <PrimaryButton
          marginTop="one"
          onClick={onButtonClick || undefined}
          alignSelf="flex-start"
        >
          {ctaText}
        </PrimaryButton>
      </CardBanner>
    </Box>
  );
};
