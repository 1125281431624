import type { GDSShadows } from '@leagueplatform/genesis-core';

export const shadows: GDSShadows = {
  card: {
    x: 0,
    y: 2,
    blur: 6,
    spread: 0,
    color: '#00000014',
  },
  dropdown: {
    x: 0,
    y: 4,
    blur: 8,
    spread: 0,
    color: '#0000001a',
  },
};
