import { socketAsFetch } from 'utils/socket-as-fetch';

const ACCEPT_MEMBER_TERMS_TYPE: string = 'accept_member_terms';
// not a versioned API but the field is required; 2.0 is used for lots of backend WS tests
const MOCK_TERMS_VERSION: string = '2.0';

export const acceptUserTerms = () =>
  socketAsFetch({
    message_type: ACCEPT_MEMBER_TERMS_TYPE,
    info: {
      version: MOCK_TERMS_VERSION,
    },
  });
