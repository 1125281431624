import * as React from 'react';
import {
  AppBarComponent,
  AppBarItem,
  DropdownSection,
} from '@leagueplatform/web-common-components';
import { Image } from '@leagueplatform/genesis-commons';
import { APP_NAV_DEFAULT_HEIGHT } from 'common/constants';
import { BrandingInfo } from 'hooks/use-get-app-init-details';
import { NavLink } from 'hooks/use-nav-links-list.hook';
import { NavLinkWrapper } from './nav-link-wrapper.component';
import { ResponsiveUserDropdown } from './responsive-user-dropdown.component';

export const DesktopNavbar: React.FC<{
  userDropdownConfig: DropdownSection[];
  navLinks: NavLink[];
  brandingInfo?: BrandingInfo;
}> = ({ userDropdownConfig, navLinks, brandingInfo }) => (
  <AppBarComponent navHeight={APP_NAV_DEFAULT_HEIGHT}>
    {brandingInfo?.brandingLogo && (
      <AppBarItem margin="auto">
        <Image
          height="oneAndHalf"
          role="presentation"
          alt="provider-logo"
          src={brandingInfo.brandingLogo}
        />
      </AppBarItem>
    )}

    {/* Spacer 1: Provides initial space, between logo and  list items */}
    <AppBarItem flexGrow={{ _: 1, laptop: 2 }} aria-hidden="true" />

    {/* App Nav Home & Capability Areas */}
    {navLinks.map((navLink) => (
      <NavLinkWrapper key={navLink.message_id} navLink={navLink} />
    ))}

    {/* Spacer 2: Provides flexible space between nav links and dropdown */}
    <AppBarItem flexGrow={30} aria-hidden="true" />

    <AppBarItem>
      <ResponsiveUserDropdown userDropdownConfig={userDropdownConfig} />
    </AppBarItem>

    {/* Spacer 3: Provides trailing space after dropdown */}
    <AppBarItem flexGrow={{ _: 1, laptop: 2 }} aria-hidden="true" />
  </AppBarComponent>
);
