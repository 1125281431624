import * as React from 'react';
import { Image } from '@leagueplatform/genesis-commons';
import {
  Box,
  Card,
  HeadingText,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';
import {
  MainPageContainer,
  PageHeaderBackground,
} from '@leagueplatform/web-common-components';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';

type ImageProps = {
  src: string;
  alt?: string;
};

type AboutPageProps = {
  isLoading?: boolean;
  heading: string;
  body: React.ReactNode;
  image?: ImageProps;
};

const AboutBodySection = ({
  image,
  body,
}: {
  image?: ImageProps;
  body: React.ReactNode;
}) => (
  <Card.Flat
    css={{
      borderColor: '$onSurfaceBorderSubdued',
      borderWidth: 'thin',
      borderStyle: 'solid',
    }}
  >
    <StackLayout
      orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
      horizontalAlignment="center"
      verticalAlignment="top"
      spacing="$two"
      css={{ padding: '$two' }}
    >
      {!!image && (
        <Image
          flexShrink={0}
          width="110px"
          src={image.src}
          alt={image.alt || ''}
        />
      )}
      <StackItem>{body}</StackItem>
    </StackLayout>
  </Card.Flat>
);

export const AboutPage = ({
  isLoading,
  heading,
  body,
  image,
}: AboutPageProps) => (
  <MainPageContainer>
    <PageHeaderBackground height="100%" pageHeaderBackgroundHeight="172px">
      <Box css={{ textAlign: 'left' }}>
        <HeadingText
          level="1"
          size={{ '@initial': 'xxl', '@mobile': 'xl' }}
          css={{
            marginTop: '$one',
            marginBottom: '$oneAndHalf',
            height: '$four',
            '@mobile': {
              marginTop: '$oneAndHalf',
              marginBottom: '$one',
            },
          }}
        >
          {heading}
        </HeadingText>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <AboutBodySection image={image} body={body} />
        )}
      </Box>
    </PageHeaderBackground>
  </MainPageContainer>
);
