import React from 'react';
import {
  DropdownSection,
  UserDropdownComponent,
} from '@leagueplatform/web-common-components';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { useUserDropdown } from 'hooks/use-user-dropdown.hook';

type UserDropdownProps = {
  userDropdownConfig: DropdownSection[];
};

export const ResponsiveUserDropdown = React.memo(
  ({ userDropdownConfig }: UserDropdownProps) => {
    const { data } = useUserDropdown(userDropdownConfig);
    const userDropdownAnalytics = {
      name: EVENT_NAME.NAVIGATION_BAR_CLICKED,
      props: {
        product_area: PRODUCT_AREA.HOME_SCREEN,
        screen_name: SCREEN_NAMES.HOME_SCREEN,
        detail: 'drop down menu',
      },
    };

    return (
      <UserDropdownComponent
        label={data.preferredFirstName.toUpperCase()}
        dropdownItemsConfig={data.menuItemsConfig}
        userBarAnalyticsProps={userDropdownAnalytics}
        enableMobileRedesign
      />
    );
  },
);
