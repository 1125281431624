import { useMutation } from 'react-query';
import { setUserProfile } from '@leagueplatform/user-profile-api';

export type ProfileData = {
  opted_into_marketing_communications?: boolean;
};

export const useSetUserProfile = (data: ProfileData) => {
  const updateProfile = useMutation((profileData: ProfileData) =>
    setUserProfile(profileData),
  );

  return async () => {
    try {
      return await updateProfile.mutateAsync(data);
    } catch (error) {
      throw new Error('error updating user profile');
    }
  };
};
