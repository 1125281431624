import { GDSzIndices } from '@leagueplatform/genesis-core';

export const zIndices: GDSzIndices = {
  default: 1,
  sticky: 100,
  dropdown: 4000,
  tooltip: 5000,
  modal: 8000,
  toast: 9000,
};
