import React, { useRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { ERROR_TYPES } from 'hooks/use-error-message/use-error-message';
import { useMfaEnrollmentModal } from 'hooks/use-mfa-modal.hook';
import { IframeModal } from 'components/iframe-modal/iframe-modal.component';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { ErrorState } from 'components/error/error-state.component';

export const MfaEnrollmentModal = ({
  closeModal,
  onEnrollSuccess,
}: {
  closeModal: () => void;
  onEnrollSuccess: () => void;
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { url, error, isLoading } = useMfaEnrollmentModal(
    iframeRef,
    onEnrollSuccess,
  );
  const { formatMessage } = useIntl();

  const modalTitle = formatMessage({ id: 'MFA_MODAL_TITLE' });

  return (
    <IframeModal title={modalTitle} onClose={closeModal}>
      {error ? (
        <ErrorState
          errorType={ERROR_TYPES.API_ERROR}
          hideButton
          styleProps={{ height: '100%' }}
        />
      ) : (
        <>
          {isLoading && <LoadingSpinner />}
          <iframe
            id="mfaIframeModal"
            ref={iframeRef}
            height="100%"
            width="100%"
            title={modalTitle}
            src={url}
          />
        </>
      )}
    </IframeModal>
  );
};
