import { socketAsFetch } from 'utils/socket-as-fetch';
import camelcaseKeys from 'camelcase-keys';

export const GET_APP_INIT_DETAILS_MESSAGE_TYPE = 'get_app_init_details';

export enum INELIGIBLE_STATES {
  NO_LONGER_EFFECTIVE = 'NO_LONGER_EFFECTIVE',
}

// these codes are determined and sent by Highmark's eligibility API
export enum INELIGIBLE_CODES {
  OVER_365_DAYS = 11,
}

type ContentIneligible =
  | INELIGIBLE_STATES.NO_LONGER_EFFECTIVE
  | null
  | undefined;

// These are the values sent from the BE, not the values used by the FE to define routes
export enum CONTENT_ROUTES {
  HOME = 'home',
  BENEFITS = 'benefits',
  CARE = 'getCare',
  JOURNEY = 'journey',
  SUPPORT = 'support',
}

export type PageIneligibility = {
  [CONTENT_ROUTES.HOME]: ContentIneligible;
  [CONTENT_ROUTES.BENEFITS]: ContentIneligible;
  [CONTENT_ROUTES.CARE]: ContentIneligible;
  [CONTENT_ROUTES.JOURNEY]: ContentIneligible;
  [CONTENT_ROUTES.SUPPORT]: ContentIneligible;
};

export type EligibilityInfo = {
  eligible: boolean;
  reasonCode: INELIGIBLE_CODES | number;
  reasonCodeDescription: string;
};

export interface AppInitDetails {
  brandingInfo: {
    brandingLogo: string;
    brandingLogoOpt?: string;
    brandingMessage: string;
    brandingName: string;
  };
  appMessaging: {
    disclaimer: string;
  };
  qualifiers: {
    insuranceAdministeredByThirdParty: boolean;
  };
  eligibilityInfo: EligibilityInfo;
  mfaEnrolled: boolean;
  contentConfig: PageIneligibility;
}

export const getAppInitDetails = (): Promise<AppInitDetails> =>
  socketAsFetch({
    message_type: GET_APP_INIT_DETAILS_MESSAGE_TYPE,
    // eligibility to access the app may differ between mobile and web, for the same user, due to age restrictions
    info: {
      platform: 'web',
    },
  }).then((data) => camelcaseKeys(data, { deep: true }));
