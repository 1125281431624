import React from 'react';
import {
  Disclaimer,
  FEATURE_FLAGS,
  Footer,
  useGetAppInitDetails,
  useMobileAppDownloadLinks,
} from '@highmark-web/highmark-common';
import { Image } from '@leagueplatform/genesis-commons';
import { useFooterLinks } from 'hooks/use-footer-links';
import { APP_STORE_PROD_URL, PLAY_STORE_PROD_URL } from 'common/constants';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import { useGetAppFeatureFlag } from 'hooks/use-get-app-feature-flag.hook';
import { SOCIAL_MEDIA_ICONS } from './iel-footer.config';

export const IelFooter = React.memo(() => {
  const { footerLinks } = useFooterLinks();
  const { mobileAppDownloadLinks } = useMobileAppDownloadLinks(
    APP_STORE_PROD_URL,
    PLAY_STORE_PROD_URL,
  );
  const { isLoading, appMessaging, brandingInfo } = useGetAppInitDetails();

  const { value } = useGetAppFeatureFlag(FEATURE_FLAGS.CMS_CODE);

  if (isLoading)
    return (
      <SkeletonBox
        borderRadius="none"
        height="200px"
        backgroundColor="surface.background.secondary"
      />
    );

  // Append cms code to disclaimer if set in Feature flag
  const formattedFooterDisclaimerText = `${
    appMessaging?.disclaimer
  }\n${value.asString()}`;

  return (
    <Footer
      branding={
        <Image
          src={brandingInfo?.brandingLogo}
          alt="MyHighmark Branding"
          marginBottom="twoAndHalf"
          width="170px"
        />
      }
      disclaimer={
        <Disclaimer hasLegalModal disclaimer={formattedFooterDisclaimerText} />
      }
      footerAppDownloadAssets={mobileAppDownloadLinks}
      footerIcons={SOCIAL_MEDIA_ICONS}
      footerLinks={footerLinks}
    />
  );
});
