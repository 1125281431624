import { GDSDecorativeTokens } from '@leagueplatform/genesis-core';

const decorative: GDSDecorativeTokens = {
  brand: {
    primary: {
      darkest: '#003750',
      dark: '#046B9A',
      default: '#008DD1',
      bright: '#2AB4F4',
      brightest: '#8ED3F6',
      pastel: '#ECF8FD',
    },
    secondary: {
      darkest: '#096D68',
      dark: '#0C918B',
      default: '#0FB5AE',
      bright: '#57CBC6',
      brightest: '#9FE1DF',
      pastel: '#E7F8F7',
    },
    tertiary: {
      darkest: '#3E1F99',
      dark: '#5329CC',
      default: '#6833FF', // Only default defined in Figma as of Aug 4, 2022 - remaining tokens are copies of brand.secondary
      bright: '#9570FF',
      brightest: '#C3ADFF',
      pastel: '#F0EBFF',
    },
  },
};

export const decorativeTokens = {
  decorative,
};
