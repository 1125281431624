import { isProduction } from '@leagueplatform/core';
import {
  SSO_URL_NAMES,
  QUERY_PARAM_KEYS,
  AUTHED_WEBVIEW_URL_NAMES,
  SSO_URLS_MAP,
  SsoUrlOptions,
} from 'common/sso-constants';
import { captureAppError } from '@leagueplatform/observability';
import { SENTRY_CONSTANTS } from 'common/constants';
import { highmarkCommon } from 'highmark-common.config';

export const buildSsoUrl = (options: SsoUrlOptions) => {
  const { path, linkType, baseUrlType } = options;
  const baseUrl = highmarkCommon.getConfig()[baseUrlType];

  // If baseUrl is not set, don't error out but capture error
  if (!baseUrl) {
    captureAppError(new Error('SSO URL Error - Base Url not set.'), {
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      tags: {
        ssoPath: path,
      },
      context: {
        Context: {
          ...options,
        },
      },
    });
    return '';
  }

  const urlParams = new URLSearchParams({
    [QUERY_PARAM_KEYS.linkType]: linkType,
  });
  const urlObj = new URL(baseUrl);
  urlObj.pathname = path;
  urlObj.search = urlParams.toString();
  return urlObj.toString();
};

export const getSsoUrl = (urlName: SSO_URL_NAMES) =>
  buildSsoUrl(SSO_URLS_MAP[urlName]);

export const getAuthedWebviewUrl = (urlName: AUTHED_WEBVIEW_URL_NAMES) =>
  buildSsoUrl(SSO_URLS_MAP[urlName]);

const NON_PROD_ADDITIONAL_POSTMESSAGE_ORIGINS = [
  'https://web2.dev.gyantts.com',
];

const PROD_ADDITIONAL_POSTMESSAGE_ORIGINS = [
  'https://web2.production.gyantts.com',
];

export const getTrustedTargetSsoOrigins = () => {
  const { highmarkMemberPortalUrl, highmarkSsoAppUrl } =
    highmarkCommon.getConfig();

  const trustedOrigins = [highmarkMemberPortalUrl, highmarkSsoAppUrl].filter(
    (url) => !!url,
  );

  return trustedOrigins;
};

export const getTrustedPostMessageOrigins = () => [
  ...getTrustedTargetSsoOrigins(),
  ...(!isProduction()
    ? NON_PROD_ADDITIONAL_POSTMESSAGE_ORIGINS
    : PROD_ADDITIONAL_POSTMESSAGE_ORIGINS),
];
