import { useEffect, useState } from 'react';

export type MobileAuthPayload = {
  accessToken: string;
  user: {
    'https://el/user_id': string;
  };
};

// Set up the window to expect a global __LEAGUE_AUTH__ variable
declare global {
  interface Window {
    __LEAGUE_AUTH__: { send: (state: MobileAuthPayload) => void };
  }
}

/**
 * Create a mobile auth listener that checks for the `waitForMobileAuth` cookie and provides a callback
 * for mobile to send authentication information in a mobile-app-hosted embedded webview
 */
export const useMobileAuthListener = () => {
  const shouldUseMobileAuth: boolean = document.cookie.includes(
    'waitForMobileAuth=true',
  );
  // Set loading based on if we need to wait for mobile auth
  const [isLoading, setIsLoading] = useState<boolean>(shouldUseMobileAuth);
  const [mobileAuthUserId, setMobileAuthUserId] = useState<string>('');
  const [mobileAuthToken, setMobileAuthToken] = useState<string>('');

  useEffect(() => {
    // If we're in mobile auth mode and need to wait for the payload
    if (shouldUseMobileAuth && isLoading) {
      // eslint-disable-next-line no-underscore-dangle
      window.__LEAGUE_AUTH__ = {
        send: (state: MobileAuthPayload) => {
          setMobileAuthUserId(state.user['https://el/user_id']);
          setMobileAuthToken(state.accessToken);

          // Once the payload is received, stop listening for updates
          setIsLoading(false);
        },
      };
    }
  }, [shouldUseMobileAuth, isLoading]);

  return {
    shouldUseMobileAuth,
    isLoading,
    mobileAuthUserId,
    mobileAuthToken,
  };
};
