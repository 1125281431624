import { DropdownSection } from '@leagueplatform/web-common-components';
import { BoxProps } from '@leagueplatform/genesis-commons';
import {
  AUTHED_WEBVIEW_URL_NAMES,
  getAuthedWebviewUrl,
  getSsoUrl,
  injectCobrowseInitiator,
  LinkHandler,
  SSO_URL_NAMES,
  UserDropdownProps,
} from '@highmark-web/highmark-common';

export const getUserDropdownConfig = (qualifiers: UserDropdownProps) => {
  const accountManagementUrl = getAuthedWebviewUrl(
    AUTHED_WEBVIEW_URL_NAMES.ACCOUNT_MANAGEMENT,
  );

  const cobUrl = qualifiers?.isCoordinationBenefitsV2
    ? getAuthedWebviewUrl(AUTHED_WEBVIEW_URL_NAMES.COB_V2)
    : getSsoUrl(SSO_URL_NAMES.COB);

  const userDropdownConfig: DropdownSection[] = [
    [
      {
        text: 'ACCOUNT_MANAGEMENT',
        to: accountManagementUrl,
        onClick: () => {
          LinkHandler.handleLink(accountManagementUrl);
        },
      },
      // Conditionally add COB Link to list if insuranceAdministeredByThirdParty is true
      ...(qualifiers?.insuranceAdministeredByThirdParty
        ? [
            {
              text: 'COORDINATION_OF_BENEFITS',
              to: cobUrl,
              onClick: () => {
                LinkHandler.handleLink(cobUrl);
              },
            },
          ]
        : []),
      // Conditionally add CoBrowse link to list if feature flag is enabled
      ...(qualifiers?.isCobrowseEnabled
        ? [
            {
              text: 'START_COBROWSE',
              to: '',
              onClick: () => {
                injectCobrowseInitiator();
              },
            },
          ]
        : []),
    ],
    [
      {
        text: 'ABOUT_IEL',
        to: '/about',
      },
    ],
    [
      {
        to: '/sign-out',
        text: 'LOG_OUT',
        linkProps: {
          color: 'onSurface.text.critical',
        } as BoxProps,
      },
    ],
  ];

  return userDropdownConfig;
};
