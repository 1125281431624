import {
  FeatureFlag,
  FeatureFlagProps,
  useGetFeatureFlag,
} from '@highmark-web/highmark-common';
import {
  FIREBASE_DEFAULT_VALUES,
  firebaseRemoteConfig,
} from '../common/firebase/firebase';

const featureFlagConfig: FeatureFlagProps = {
  flagName: '',
  remoteConfig: firebaseRemoteConfig,
  defaultValues: FIREBASE_DEFAULT_VALUES,
  // *NOTE: Uncomment the following lines for development/debugging settings
  // staleTimeOverride: 0, // reset to default
  // cacheTimeOverride: 5 * (60 * 1000), // reset to default (5 mins)
};

// wrapper hook to pass app-specific values to module-level hook
export const useGetAppFeatureFlag = (flagName: string): FeatureFlag => {
  const { isEnabled, isLoading, isDefault, value } = useGetFeatureFlag({
    ...featureFlagConfig,
    flagName,
  });

  return { isEnabled, isLoading, isDefault, value };
};
