import React from 'react';
import { Redirect, useLocation } from '@leagueplatform/routing';

export const DuplicatePathRedirect = () => {
  const location = useLocation();

  // Removes a duplicate path if present at the start of the URL, e.g. /benefits/benefits/id -> /benefits/id
  const removeRedundant = (path: string) => {
    const pathArr = path.split('/');
    if (pathArr[1] === pathArr[2]) {
      pathArr.splice(2, 1);
    }
    return pathArr.join('/');
  };

  return (
    <Redirect
      to={{
        ...location,
        pathname: removeRedundant(location.pathname),
      }}
    />
  );
};
