import React, { useState } from 'react';
import {
  Box,
  Caption,
  Flex,
  genesisStyled,
  HeadingTwo,
  Image,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { Form, Formik } from 'formik';
import { useAcceptUserTerms } from 'hooks/use-accept-user-terms';
import { ProfileData, useSetUserProfile } from 'hooks/use-set-user-profile';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { TermsLinksContent } from 'components/home/app-walkthrough/terms-links-content.component';
import { FormikCheckBox } from 'components/form/formik-checkbox.component';

type UserSetupFormData = {
  areTermsAccepted: boolean;
  isEmailOptedIn: boolean;
};

const NoWrapCaption = genesisStyled(Caption)`
    white-space: nowrap;
  `;

const handleKeyPress = (e: React.KeyboardEvent, formProps: any) => {
  if (e.key === 'Enter' && (e.target as HTMLInputElement).type === 'checkbox') {
    // this is needed to work around Formik's default of submitting on hitting enter
    e.preventDefault();
    const targetName: string = (e.target as HTMLInputElement).name;
    formProps.setFieldValue([targetName], !formProps.values[targetName]);
  }
};

const validateForm = (
  values: UserSetupFormData,
  formatMessage: (descriptor: { id: string }) => string,
) => {
  const errors: { [key: string]: any } = {};
  if (!values.areTermsAccepted) {
    errors.areTermsAccepted = formatMessage({ id: 'ERROR_CAPTION_TERMS' });
  }
  return errors;
};

export const UserSetupFormComponent = ({
  formImage,
  handleExit,
}: {
  formImage: string;
  handleExit: () => void;
}) => {
  const { formatMessage } = useIntl();
  const acceptUserTerms = useAcceptUserTerms();
  const optInEmails: ProfileData = {
    opted_into_marketing_communications: true,
  };
  const setOptInEmails = useSetUserProfile(optInEmails);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (formValues: UserSetupFormData) => {
    setLoading(true);

    // show error and keep user on slide only if terms call fails, not if email opt-in profile call fails
    if (formValues.isEmailOptedIn) {
      try {
        await setOptInEmails();
      } catch {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR_CAPTION_PROFILE',
        });
      }
    }

    try {
      const termsApiCall = await acceptUserTerms();
      if (termsApiCall.success) {
        setLoading(false);
        handleExit();
      } else {
        setLoading(false);
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR_CAPTION_SAVE',
        });
      }
    } catch {
      setLoading(false);
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'ERROR_CAPTION_SAVE',
      });
    }
  };

  return loading ? (
    <Flex className="spinner-container" height="100vh" justifyContent="center">
      <LoadingSpinner />
    </Flex>
  ) : (
    <Flex flexDirection="column" alignItems="center" width="360px">
      {formImage && (
        <Image
          src={formImage}
          alt=""
          width="176px"
          height="auto"
          marginBottom="two"
        />
      )}
      <HeadingTwo marginBottom="half">
        {formatMessage({ id: 'ACCOUNT_SETUP_FORM_HEADING' })}
      </HeadingTwo>
      <Formik
        initialValues={{ areTermsAccepted: false, isEmailOptedIn: false }}
        onSubmit={(formProps) => handleSubmit(formProps)}
        validate={(values: UserSetupFormData) =>
          validateForm(values, formatMessage)
        }
        validateOnBlur={false}
        validateOnChange
      >
        {(formProps) => (
          <Form
            onKeyPress={(e: React.KeyboardEvent) =>
              handleKeyPress(e, formProps)
            }
          >
            <FormikCheckBox
              fieldId="terms"
              fieldName="areTermsAccepted"
              fieldBody={<TermsLinksContent />}
              formErrors={formProps.errors}
              spaceProps={{
                marginTop: 'one',
                marginBottom: formProps.errors.areTermsAccepted
                  ? 'half'
                  : 'twoAndHalf',
              }}
            />
            {formProps.errors.areTermsAccepted && (
              <NoWrapCaption
                color="onSurface.text.critical"
                marginLeft="34px"
                marginBottom="one"
              >
                {formatMessage({ id: 'ERROR_CAPTION_TERMS' })}
              </NoWrapCaption>
            )}
            <FormikCheckBox
              fieldId="email"
              fieldName="isEmailOptedIn"
              fieldBody={formatMessage({ id: 'EMAIL_OPT_IN' })}
              formErrors={formProps.errors}
            />
            <Box position="absolute" right="twoAndHalf" bottom="quarter">
              <PrimaryButton flex="0 1 auto" type="submit" disabled={loading}>
                {formatMessage({ id: 'DONE' })}
              </PrimaryButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
