import Masonry from '@leagueplatform/masonry';
import { SCREEN_NAMES, usePageViewAnalytics } from '@leagueplatform/analytics';
import React from 'react';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { MASONRY } from 'common/constants';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { MasonryPageContainer } from 'pages/masonry/masonry-container.page';

const SupportPageMasonry = Masonry.init({
  appId: MASONRY.APP_ID_SUPPORT_PAGE,
  analytics: {
    productArea: SCREEN_NAMES.SUPPORT,
    screenName: SCREEN_NAMES.SUPPORT,
  },
  uiConfig: {
    CustomLoader: () => <LoadingSpinner />,
  },
  apiVersion: MASONRY.API_VERSION,
});

export const SupportPage = () => {
  usePageViewAnalytics({ screen_name: SCREEN_NAMES.SUPPORT });
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'SUPPORT_PAGE' }));

  return (
    <MasonryPageContainer>
      <SupportPageMasonry.Provider />
    </MasonryPageContainer>
  );
};
