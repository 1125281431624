import React from 'react';
import {
  AssetLink,
  AssetLinkProps,
} from 'components/asset-links/asset-link.component';
import { FooterLinkProps } from 'components/footer/footer-items/footer-link-items.component';
import { CONTENT_MAX_WIDTH } from '@leagueplatform/web-common-components';
import { StackLayout } from '@leagueplatform/genesis-core';
import { FooterLinksAndIconsSection } from './footer-items/footer-links-container.component';

const FooterLayoutWrapper = ({ children }: any) => (
  <StackLayout
    orientation={{
      '@initial': 'vertical',
      '@laptop': 'horizontal',
      '@desktop': 'horizontal',
    }}
    horizontalAlignment="center"
    css={{
      padding: '$two $one',
      '@laptop': {
        padding: '$none $one',
      },
      '@desktop': {
        padding: '$none $four',
      },
      backgroundColor: '$surfaceBackgroundSecondary',
    }}
  >
    {children}
  </StackLayout>
);

export const Footer = ({
  disclaimer,
  footerAppDownloadAssets,
  branding,
  footerLinks,
  footerIcons,
}: {
  disclaimer: React.ReactNode;
  footerAppDownloadAssets: AssetLinkProps[];
  branding?: React.ReactNode;
  footerLinks: FooterLinkProps[];
  footerIcons?: AssetLinkProps[];
}) => (
  <FooterLayoutWrapper>
    {/* Branding + App Download Links Section */}
    <StackLayout
      orientation="vertical"
      horizontalAlignment="center"
      spacing="$one"
      css={{
        flexGrow: 0,
        margin: '$one',
        '@laptop': {
          margin: '$twoAndHalf',
        },
        '@desktop': {
          margin: '$twoAndHalf',
        },
      }}
    >
      {!!branding && branding}
      <StackLayout spacing="$half">
        {footerAppDownloadAssets.map((item) => (
          <AssetLink
            key={item.url}
            src={item.src}
            alt={item.alt}
            url={item.url}
            styleProps={{
              marginX: { _: 'quarter', laptop: 'threeQuarters' },
              width: '170px',
            }}
          />
        ))}
      </StackLayout>
    </StackLayout>

    {/* External and Social Links and Disclaimer Section */}
    <StackLayout
      orientation="vertical"
      horizontalAlignment={{
        '@initial': 'center',
        '@laptop': 'stretch',
        '@desktop': 'stretch',
      }}
      css={{
        margin: '$one',
        maxWidth: CONTENT_MAX_WIDTH,
        '@laptop': {
          margin: '$twoAndHalf',
        },
        '@desktop': {
          margin: '$twoAndHalf',
        },
      }}
    >
      <FooterLinksAndIconsSection
        footerLinks={footerLinks}
        footerIcons={footerIcons}
      />
      {disclaimer}
    </StackLayout>
  </FooterLayoutWrapper>
);
