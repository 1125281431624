/* eslint-disable jsx-a11y/anchor-is-valid */
import { genesisStyled, Link } from '@leagueplatform/genesis-commons';
import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useConfigProperty } from '@leagueplatform/core';
import { getAuthedWebviewUrl } from 'utils/sso-url-helpers';
import { AUTHED_WEBVIEW_URL_NAMES } from 'common/sso-constants';

export const TermsLinksContent = () => {
  const { formatMessage } = useIntl();

  const handleLink = useConfigProperty('core.customMethods.handleLink');

  const handleClick = (event: MouseEvent, url: string) => {
    event.preventDefault();
    handleLink(url);
  };

  const AnchorLink = genesisStyled(Link)`
    background-color: inherit;
    border: none;
    font-weight: bold;
    text-decoration: underline;
`;
  const privacyUrl = getAuthedWebviewUrl(
    AUTHED_WEBVIEW_URL_NAMES.PRIVACY_POLICY,
  );
  const termsUrl = getAuthedWebviewUrl(AUTHED_WEBVIEW_URL_NAMES.TERMS);
  return (
    <>
      <span>{formatMessage({ id: 'TERMS_AGREEMENT' })}</span>
      <AnchorLink
        href={privacyUrl}
        onClick={(event: MouseEvent) => handleClick(event, privacyUrl)}
      >
        {formatMessage({ id: 'PRIVACY_POLICY' })}
      </AnchorLink>
      <span>&nbsp;{formatMessage({ id: 'AND' })}&nbsp;</span>
      <AnchorLink
        href={termsUrl}
        onClick={(event: MouseEvent) => handleClick(event, termsUrl)}
      >
        {formatMessage({ id: 'TERMS_SERVICE' })}
      </AnchorLink>
      <span>.</span>
    </>
  );
};
