import * as React from 'react';
import {
  Box,
  css,
  Flex,
  genesisStyled,
  useTheme,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import spinner from 'assets/loading.png';

const AnimatedSpinner = genesisStyled(Box)(
  css({
    height: '48px',
    width: '48px',
    backgroundImage: `url(${spinner})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '36px 22px',
    animation: 'rotate 1s linear infinite forwards',
    '@keyframes rotate': {
      to: {
        transform: 'rotate(360deg)',
      },
    },
  }),
);

const SpinnerPositioner = genesisStyled(Flex)(
  css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
);

const CircleOutline = genesisStyled(SpinnerPositioner)(
  css({
    borderWidth: '6px',
    borderStyle: 'solid',
    borderRadius: '100px',
    height: '48px',
    width: '48px',
  }),
);

const LoadingContainer = genesisStyled(Box)(
  css({
    width: '50px',
    height: '50px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
);

export const LoadingSpinner = ({ ...props }: any) => {
  const { colors } = useTheme();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <LoadingContainer {...props}>
      <CircleOutline
        aria-hidden="true"
        /* Fallback to handle when the spinner is called before initialization of the League Provider (while waiting for auth) */
        borderColor={colors?.decorative.brand.primary.pastel || '#ECF8FD'}
      />
      <SpinnerPositioner aria-hidden="true">
        <AnimatedSpinner />
      </SpinnerPositioner>
      <VisuallyHidden>Loading.</VisuallyHidden>
    </LoadingContainer>
  );
};

LoadingSpinner.displayName = 'LoadingSpinner';
