import { GDSInputTokens } from '@leagueplatform/genesis-core';

const input: GDSInputTokens = {
  background: {
    default: '#FFFFFF',
    disabled: '#E8EAED',
    hovered: '#F8F8F8',
    pressed: '#F0F0F0',
  },
  border: {
    default: '#949494',
    disabled: '#D6D6D6',
    hovered: '#005696',
    critical: '#A12127',
    success: '#159369',
    warning: '#DE6F12',
  },
};

export const inputTokens = {
  input,
};
