import React from 'react';
import { MainPageContainer } from '@leagueplatform/web-common-components';

export const MasonryPageContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <MainPageContainer
    // Override padding in MainPageContainer, masonry component headers have top padding already
    padding="none"
    paddingX={{ _: 'oneAndHalf', phone: 'two' }}
    paddingBottom={{ _: 'oneAndHalf', phone: 'two' }}
  >
    {children}
  </MainPageContainer>
);
