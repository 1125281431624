import { Value, getBoolean, getValue } from 'firebase/remote-config';
import {
  FetchAndActivateProps,
  useFetchAndActivate,
} from './use-fetch-and-activate.hook';

export type FeatureFlag = {
  isEnabled: boolean;
  value: Value;
  isLoading: boolean;
  isDefault: boolean; // true indicates fetching the remote config is loading or returned an error
};

export type FeatureFlagProps = FetchAndActivateProps & {
  flagName: string;
  defaultValues: NonNullable<FetchAndActivateProps['defaultValues']>;
};

/**
 * Returns the value of a remote config feature flag
 * @param {object} FeatureFlagProps
 * @returns {object} FeatureFlag object
 */
export const useGetFeatureFlag = ({
  flagName,
  defaultValues,
  remoteConfig,
  staleTimeOverride,
  cacheTimeOverride,
}: FeatureFlagProps): FeatureFlag => {
  const { isLoading, isError } = useFetchAndActivate({
    remoteConfig,
    staleTimeOverride,
    cacheTimeOverride,
    defaultValues,
  });

  const isDefault: boolean = isLoading || isError;

  return {
    isEnabled: getBoolean(remoteConfig, flagName),
    value: getValue(remoteConfig, flagName),
    isLoading,
    isDefault,
  };
};
