import { GDSStatusTokens } from '@leagueplatform/genesis-core';

// Critical colours are for destructive interactive elements, errors, and critical events that require immediate action.
const critical: GDSStatusTokens = {
  background: {
    subdued: '#F9E1E2',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#A12127',
  },
  icon: '#CB2A32',
};

// Warning colours let the user know they need to take action.
const warning: GDSStatusTokens = {
  background: {
    subdued: '#FAE8D6',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#DE6F12',
  },
  icon: '#DE6F12',
};

// Success colours indicate something positive, like the success of an action.
const success: GDSStatusTokens = {
  background: {
    subdued: '#E3F7F0',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#159369',
  },
  icon: '#159369',
};

//  Highlight colours indicate important elements that don’t require immediate action.
const highlight: GDSStatusTokens = {
  background: {
    subdued: '#EAF5FD',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#2AB4F4',
  },
  icon: '#2AB4F4',
};

export const statusTokens = {
  critical,
  warning,
  success,
  highlight,
};
