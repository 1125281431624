import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import HomeIcon from 'assets/nav/home.svg';
import BenefitsIcon from 'assets/nav/wallet.svg';
import GetCareIcon from 'assets/nav/get-care.svg';
import SupportIcon from 'assets/nav/support.svg';
import JourneyIcon from 'assets/nav/journey.svg';
import HomeSelectedIcon from 'assets/nav/home-selected.svg';
import BenefitsSelectedIcon from 'assets/nav/wallet-selected.svg';
import GetCareSelectedIcon from 'assets/nav/get-care-selected.svg';
import JourneySelectedIcon from 'assets/nav/journey-selected.svg';
import SupportSelectedIcon from 'assets/nav/support-selected.svg';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';

export enum NavIds {
  HOME = 'HOME',
  BENEFITS = 'BENEFITS',
  CARE = 'GET_CARE',
  JOURNEY = 'JOURNEY',
  SUPPORT = 'SUPPORT',
}

export enum LinkType {
  NAV = 'navLink',
  LINK = 'link',
}

type NavItem = {
  to:
    | string
    | {
        search: string;
        pathname: string;
        state: unknown;
        hash: string;
        key?: string | undefined;
      };
};

export interface NavLink extends NavItem {
  message_id: NavIds;
  type: LinkType;
  icon?: string;
  iconSelected?: string;
}

export const useNavLinksList = () => {
  const NavLinksList: NavLink[] = [
    {
      to: '/home',
      message_id: NavIds.HOME,
      type: LinkType.NAV,
      icon: HomeIcon,
      iconSelected: HomeSelectedIcon,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.wallet),
      message_id: NavIds.BENEFITS,
      type: LinkType.NAV,
      icon: BenefitsIcon,
      iconSelected: BenefitsSelectedIcon,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.careDiscovery),
      message_id: NavIds.CARE,
      type: LinkType.NAV,
      icon: GetCareIcon,
      iconSelected: GetCareSelectedIcon,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
      message_id: NavIds.JOURNEY,
      type: LinkType.NAV,
      icon: JourneyIcon,
      iconSelected: JourneySelectedIcon,
    },
    {
      to: APP_MANAGED_ROUTES.SUPPORT,
      message_id: NavIds.SUPPORT,
      type: LinkType.NAV,
      icon: SupportIcon,
      iconSelected: SupportSelectedIcon,
    },
  ];

  return {
    navLinks: NavLinksList,
  };
};
