import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import highmarkLogo from 'assets/highmark-logo.svg';
import { AboutPage, useGetAppInitDetails } from '@highmark-web/highmark-common';
import { ParagraphText, StackLayout } from '@leagueplatform/genesis-core';

export const AboutIel = () => {
  const { formatMessage } = useIntl();
  const { isLoading, appMessaging } = useGetAppInitDetails();
  return (
    <AboutPage
      isLoading={isLoading}
      heading={formatMessage({ id: 'ABOUT_IEL' })}
      image={{ src: highmarkLogo }}
      body={
        <StackLayout spacing="$threeQuarters">
          <ParagraphText>
            {formatMessage({ id: 'ABOUT_IEL_BODY_1' })}
          </ParagraphText>
          {appMessaging?.disclaimer && (
            <ParagraphText>{appMessaging.disclaimer}</ParagraphText>
          )}
        </StackLayout>
      }
    />
  );
};
