import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import React from 'react';

interface BannerWidgetProps {
  heading: string;
  body: string;
  image: string;
  imageAltText: string;
}

export const BannerWidget = ({
  heading,
  image,
  body,
  imageAltText,
}: BannerWidgetProps) => (
  <StackLayout
    orientation="horizontal"
    verticalAlignment="center"
    css={{
      boxShadow: '$card',
      borderStyle: 'solid',
      padding: '$one',
    }}
  >
    <Image
      src={image}
      width="36px"
      height="36px"
      marginRight="one"
      alt={imageAltText}
    />
    <StackLayout orientation="vertical">
      <UtilityText
        size="sm"
        emphasis="emphasized"
        css={{ marginBottom: '$quarter' }}
      >
        {heading}
      </UtilityText>
      <UtilityText size="sm" emphasis="subtle">
        {body}
      </UtilityText>
    </StackLayout>
  </StackLayout>
);
