import React from 'react';
import {
  BackgroundColorSystemProp,
  Box,
  SystemPropType,
} from '@leagueplatform/genesis-commons';

export const FullScreenContainer = ({
  backgroundColor,
  children,
}: {
  backgroundColor: SystemPropType<BackgroundColorSystemProp>;
  children: React.ReactNode;
}) => (
  <Box
    role="dialog"
    width="100vw"
    height="100vh"
    position="fixed"
    top="0"
    left="0"
    zIndex="100"
    backgroundColor={backgroundColor}
  >
    {children}
  </Box>
);
