import { isProduction } from '@leagueplatform/core';
import log from 'loglevel';

/**
 * A console log wrapper that will ensure non error logs don't show up in production. Wrap log method to always check appEnvironment before logging to console.
 */
const originalFactory = log.methodFactory;

log.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return (...args) => {
    if (!isProduction() || methodName === 'error') {
      rawMethod(...args);
    }
  };
};

log.rebuild();

export const logger = log;
