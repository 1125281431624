import * as React from 'react';
import { useEffect } from 'react';
import { useAuth } from '@leagueplatform/auth-utils';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { clearHighmarkSessionRedirect } from 'utils/clear-session-redirect';
import { captureAuthenticationError } from 'utils/sentry-helpers';
import { useAuthLoginRedirect } from 'hooks/use-auth-login-redirect/use-auth-login-redirect.hook';

export const AuthSessionContainer = ({
  children,
}: {
  children: JSX.Element;
}) => {
  /**
   * The Highmark apps can initialize either in standalone auth mode or in
   * embedded auth mode. With the latter, the app is displayed in a mobile
   * webview, and we receive authentication from the mobile app.
   * The `useAuth` hook is usable whether the app is initialized
   * in standalone mode or in embedded mode - when in embedded mode,
   * the `useAuth` hook ASSUMES that we're authenticated and relies on
   * EmbeddedAuth to throw an error if authentication is not passed.
   */
  const { isAuthenticated, error: authError } = useAuth();
  /**
   * When in standalone mode, we cannot rely on Auth0's state property to remember
   * deeplink values. Therefore, we use our own `useAuthLoginRedirect` hook
   * to handle login redirects instead of the flag in the `useAuth` hook.
   */
  useAuthLoginRedirect(isAuthenticated);

  useEffect(() => {
    // Redirect to landing page if an error occurs during authentication
    // TODO: Improve UX when redirected due to auth error: https://everlong.atlassian.net/browse/HMRK-1609
    if (authError) {
      captureAuthenticationError(authError);
      clearHighmarkSessionRedirect();
    }
  }, [authError]);

  if (!isAuthenticated) return <LoadingSpinner />;

  return children;
};
