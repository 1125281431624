import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { LANGUAGE_ASSISTANCE_TITLE_DESCRIPTIONS_CONFIG } from 'common/assistance-language-options-config';
import { useIsAuthenticated } from 'hooks/use-is-authenticated';
import { useGetAppInitDetails } from 'hooks/use-get-app-init-details';
import { useHistory } from '@leagueplatform/routing';
import {
  Box,
  HeadingText,
  ParagraphText,
  StackLayout,
  queryHelpers,
  useMediaQuery,
  TextAction,
} from '@leagueplatform/genesis-core';
import {
  PageHeaderBackground,
  BackButtonArrow,
  MainPageContainer,
} from '@leagueplatform/web-common-components';

interface BackButtonArrowCheckProps {
  isPostAuth?: boolean;
}

const AvailableLanguagesAndDescriptions = () => (
  <Box>
    {LANGUAGE_ASSISTANCE_TITLE_DESCRIPTIONS_CONFIG.map(
      ({ title, description, isRtlLanguage, id, languageCode }) => (
        <Box
          key={id}
          dir={isRtlLanguage ? 'rtl' : undefined}
          lang={languageCode}
        >
          <HeadingText
            size="sm"
            level="3"
            css={{
              lineHeight: '$subtitleTwo',
              fontWeight: 'bold',
              color: '$onSurfaceTextSubdued',
              marginTop: '$two',
            }}
          >
            {title}
          </HeadingText>
          <ParagraphText
            css={{
              color: '$onSurfaceTextSubdued',
            }}
          >
            {description}
          </ParagraphText>
        </Box>
      ),
    )}
  </Box>
);

const BackButtonArrowCheck = ({ isPostAuth }: BackButtonArrowCheckProps) => {
  const history = useHistory();
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  if (!isPostAuth && !isMobile) {
    return (
      <BackButtonArrow
        onClick={() => history.goBack()}
        marginRight="two"
        marginTop="quarter"
      />
    );
  }

  if (!isPostAuth && isMobile) return null;

  if (isPostAuth && !isMobile) return null;

  if (isPostAuth && isMobile) {
    return (
      <TextAction
        onClick={() => history.goBack()}
        as="button"
        icon="interfaceChevronLeft"
        css={{
          color: '$onSurfaceIconPrimary',
          marginBottom: '$half',
        }}
      >
        {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
        {''}
      </TextAction>
    );
  }

  return (
    <BackButtonArrow
      onClick={() => history.goBack()}
      marginRight="two"
      marginTop="quarter"
    />
  );
};

export const AssistanceAndLanguagePage = () => {
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useIsAuthenticated();
  const { eligibilityInfo } = useGetAppInitDetails({
    enabled: !!isAuthenticated,
  });

  const isPostAuth = isAuthenticated && eligibilityInfo?.eligible;

  return (
    <MainPageContainer>
      <PageHeaderBackground pageHeaderBackgroundHeight="184px">
        <Box
          css={{
            display: 'flex',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '$three',
            '@mobile': {
              display: 'block',
            },
          }}
        >
          <BackButtonArrowCheck isPostAuth={isPostAuth} />
          <HeadingText size="xxl" level="1">
            {isPostAuth
              ? formatMessage({
                  id: 'ALL_LANGUAGES',
                })
              : formatMessage({
                  id: 'TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES_TITLE',
                })}
          </HeadingText>
        </Box>
      </PageHeaderBackground>
      <StackLayout
        css={{
          marginTop: '60px',
          width: '100%',
        }}
      >
        {!isPostAuth && (
          <Box>
            <HeadingText size="lg" level="2">
              {formatMessage({ id: 'TECHNICAL_SUPPORT' })}
            </HeadingText>
            <ParagraphText css={{ marginTop: '$one' }}>
              {formatMessage({ id: 'TECHNICAL_SUPPORT_DESCRIPTION' })}
            </ParagraphText>
          </Box>
        )}
        <HeadingText size="lg" level="2" css={{ marginY: '$two' }}>
          {formatMessage({ id: 'FOR_ASSISTANCE_WITH_LANGUAGES' })}
        </HeadingText>
        <Box>
          <AvailableLanguagesAndDescriptions />
        </Box>
      </StackLayout>
    </MainPageContainer>
  );
};
