import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  EVENT_NAME,
  SCREEN_NAMES,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { useHistory } from '@leagueplatform/routing';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import { useGetHsaRegistrationEligibility } from 'hooks/use-get-hsa-registration-eligibility.hook';
import { HomeCardComponent } from '../home-card/home-card.component';

type HsaSectionProps = {
  image: string;
};

export const HsaSection = ({ image }: HsaSectionProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { data, isLoading } = useGetHsaRegistrationEligibility();

  const handleHsaHomeCardAction = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: SCREEN_NAMES.HOME_SCREEN,
      screen_name: SCREEN_NAMES.HOME_SCREEN,
      detail: 'HSA Home Page Card',
    });
    history.push('/hsa');
  };

  return (
    <>
      {isLoading && (
        <SkeletonBox
          height="250px"
          backgroundColor="surface.background.secondary"
        />
      )}
      {!isLoading && data?.isHsaRegistrationEligible && (
        <HomeCardComponent
          heading={formatMessage({ id: 'HSA_HEADER' })}
          subheading={formatMessage({ id: 'HSA_SUBHEADER' })}
          body={formatMessage({ id: 'HSA_BODY' })}
          ctaText={formatMessage({ id: 'HSA_CTA' })}
          onButtonClick={handleHsaHomeCardAction}
          image={image}
        />
      )}
    </>
  );
};
