import {
  GDSPrimaryButtonTokens,
  GDSSecondaryButtonTokens,
  GDSTertiaryButtonTokens,
} from '@leagueplatform/genesis-core';

// Use primary colours for primary buttons.
const primary: GDSPrimaryButtonTokens = {
  background: {
    default: '#0074C9',
    hovered: '#005696',
    pressed: '#003963',
    critical: {
      default: '#CB2A32',
      hovered: '#A12127',
      pressed: '#611417',
    },
  },
  text: {
    default: '#FFFFFF',
    critical: {
      default: '#FFFFFF',
    },
  },
};

// Use secondary colours for secondary buttons.
const secondary: GDSSecondaryButtonTokens = {
  background: {
    default: 'transparent',
    hovered: '#5A656D',
    pressed: '#434B51',
  },
  text: {
    default: '#434B51',
    hovered: '#FFFFFF',
  },
  border: {
    default: '#707E89',
  },
};

// Use tertiary colours for tertiary buttons.
const tertiary: GDSTertiaryButtonTokens = {
  background: {
    default: 'transparent',
    hovered: '#F0FAFF',
    pressed: '#C9E8FF',
  },
  text: {
    default: '#0074C9',
    hovered: '#005696',
  },
  border: {
    default: '#707E89',
  },
};

export const buttonTokens = {
  primary,
  secondary,
  tertiary,
};
