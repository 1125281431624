export const QUERY_PARAM_KEYS = {
  modal: 'modal',
  linkType: 'linkType',
  partnerId: 'partnerId',
  ssoDeeplink: 'sso-deeplink',
};

export const WEBVIEW_MODAL_QUERY_PARAM_VALUE = 'webview-modal';

export enum LINK_TYPES {
  AUTHED_WEBVIEW = 'authedwebview',
  SSO = 'sso',
}

export enum SSO_BASE_URL_TYPES {
  MEMBER_PORTAL_URL = 'highmarkMemberPortalUrl',
  SSO_APP_URL = 'highmarkSsoAppUrl',
}

export const SSO_READY_LISTENER_TIMEOUT = 20000;

export enum SSO_URL_NAMES {
  COB = 'COB',
  E_BILL = 'E_BILL',
  PRIVACY = 'PRIVACY',
  SAPPHIRE = 'SAPPHIRE',
  TERMS_OF_USE = 'TERMS_OF_USE',
}

export enum AUTHED_WEBVIEW_URL_NAMES {
  ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT',
  COB_V2 = 'COB_V2',
  CONTACT_INFO = 'CONTACT_INFO',
  LEGAL = 'LEGAL',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS = 'TERMS',
  MFA_ENROLLMENT = 'MFA_ENROLLMENT',
}

export interface SsoUrlOptions {
  path: string;
  linkType: LINK_TYPES;
  baseUrlType: SSO_BASE_URL_TYPES;
}

export const SSO_URLS_MAP: Record<
  AUTHED_WEBVIEW_URL_NAMES | SSO_URL_NAMES,
  SsoUrlOptions
> = {
  [SSO_URL_NAMES.COB]: {
    path: 'link/cob',
    linkType: LINK_TYPES.SSO,
    baseUrlType: SSO_BASE_URL_TYPES.MEMBER_PORTAL_URL,
  },
  [SSO_URL_NAMES.E_BILL]: {
    path: 'partner/ebill',
    linkType: LINK_TYPES.SSO,
    baseUrlType: SSO_BASE_URL_TYPES.SSO_APP_URL,
  },
  [SSO_URL_NAMES.TERMS_OF_USE]: {
    path: '/legal/terms-of-use',
    linkType: LINK_TYPES.SSO,
    baseUrlType: SSO_BASE_URL_TYPES.MEMBER_PORTAL_URL,
  },
  [SSO_URL_NAMES.PRIVACY]: {
    path: '/legal/privacy',
    linkType: LINK_TYPES.SSO,
    baseUrlType: SSO_BASE_URL_TYPES.MEMBER_PORTAL_URL,
  },
  [SSO_URL_NAMES.SAPPHIRE]: {
    path: 'partner/sapphire',
    linkType: LINK_TYPES.SSO,
    baseUrlType: SSO_BASE_URL_TYPES.SSO_APP_URL,
  },
  [AUTHED_WEBVIEW_URL_NAMES.ACCOUNT_MANAGEMENT]: {
    path: 'account-management',
    linkType: LINK_TYPES.AUTHED_WEBVIEW,
    baseUrlType: SSO_BASE_URL_TYPES.SSO_APP_URL,
  },
  [AUTHED_WEBVIEW_URL_NAMES.COB_V2]: {
    path: 'coordination-of-benefits',
    linkType: LINK_TYPES.AUTHED_WEBVIEW,
    baseUrlType: SSO_BASE_URL_TYPES.SSO_APP_URL,
  },
  [AUTHED_WEBVIEW_URL_NAMES.CONTACT_INFO]: {
    path: 'contact-info',
    linkType: LINK_TYPES.AUTHED_WEBVIEW,
    baseUrlType: SSO_BASE_URL_TYPES.SSO_APP_URL,
  },
  [AUTHED_WEBVIEW_URL_NAMES.LEGAL]: {
    path: 'legal',
    linkType: LINK_TYPES.AUTHED_WEBVIEW,
    baseUrlType: SSO_BASE_URL_TYPES.SSO_APP_URL,
  },
  [AUTHED_WEBVIEW_URL_NAMES.MFA_ENROLLMENT]: {
    path: 'mfa-management',
    linkType: LINK_TYPES.AUTHED_WEBVIEW,
    baseUrlType: SSO_BASE_URL_TYPES.SSO_APP_URL,
  },
  [AUTHED_WEBVIEW_URL_NAMES.PRIVACY_POLICY]: {
    path: 'legal/privacy-policy',
    linkType: LINK_TYPES.AUTHED_WEBVIEW,
    baseUrlType: SSO_BASE_URL_TYPES.SSO_APP_URL,
  },
  [AUTHED_WEBVIEW_URL_NAMES.TERMS]: {
    path: 'legal/terms-of-use',
    linkType: LINK_TYPES.AUTHED_WEBVIEW,
    baseUrlType: SSO_BASE_URL_TYPES.SSO_APP_URL,
  },
};
