import { GetAssistanceNow } from '@leagueplatform/care-discovery';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import React from 'react';
import {
  BENEFIT_IDS,
  useGetAssistance,
} from 'components/home/get-assistance-section/use-get-assistance.hook';

export const GetAssistanceSection = ({
  targetBenefits,
}: {
  targetBenefits: BENEFIT_IDS[];
}) => {
  const { data, isLoading, isError } = useGetAssistance(targetBenefits);

  if (isLoading) {
    return (
      <SkeletonBox
        height="250px"
        backgroundColor="surface.background.secondary"
      />
    );
  }

  if (isError || data.length === 0) {
    return null;
  }

  return <GetAssistanceNow careProviders={data} />;
};
