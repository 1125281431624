import { BoxProps, Image, Link } from '@leagueplatform/genesis-commons';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import React from 'react';
import { useIsAuthenticated } from 'hooks/use-is-authenticated';

export interface AssetLinkProps {
  src: string;
  alt: string;
  url: string;
  styleProps?: BoxProps;
}

export const AssetLink = ({ src, alt, url, styleProps }: AssetLinkProps) => {
  const { isAuthenticated } = useIsAuthenticated();

  const trackAnalytics = (targetDetail: string) => {
    if (isAuthenticated) {
      trackAnalyticsEvent(EVENT_NAME.RESOURCE_CLICKED, {
        detail: targetDetail,
        sub_product_area: 'footer',
      });
    }
  };

  return (
    <Link
      href={url}
      border="none"
      hoverStyle={{
        backgroundColor: 'transparent !important',
      }}
      onClick={() => trackAnalytics(alt)}
      target="_blank"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...styleProps}
    >
      <Image src={src} alt={alt} />
    </Link>
  );
};
