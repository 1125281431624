import * as React from 'react';
import {
  BodyOne,
  Box,
  HeadingThree,
  Image,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { ModalWrapper } from '../modal-wrapper/modal-wrapper.component';

type HsaModalProps = {
  onClose: () => void;
  isOpen: Boolean;
  image: string;
};

export const HsaSuccessModal = ({ onClose, isOpen, image }: HsaModalProps) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  if (!isOpen) return null;
  return (
    <ModalWrapper
      onClose={onClose}
      modalLabel={formatMessage({ id: 'HSA_MODAL_LABEL' })}
    >
      <Box textAlign="center">
        <HeadingThree id="modalTitle" marginBottom="oneAndHalf">
          {formatMessage({ id: 'HSA_MODAL_HEADER' })}
        </HeadingThree>
        <Box>
          <Image
            src={image}
            alt={formatMessage({ id: 'HSA_ACCOUNT_CREATED' })}
          />
        </Box>
        <BodyOne marginY="oneAndHalf" textAlign="left">
          {formatMessage({ id: 'HSA_MODAL_BODY' })}
        </BodyOne>
        <PrimaryButton
          width="100%"
          onClick={() => history.push('/home')}
          data-testid="modal-okay-button"
        >
          {formatMessage({ id: 'OKAY' })}
        </PrimaryButton>
      </Box>
    </ModalWrapper>
  );
};
