import League, { LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { highmarkCommon } from '@highmark-web/highmark-common';
import { formsLibrary } from '@leagueplatform/engen-capability-forms-web';

const formsLibraryApiUrl = import.meta.env.VITE_HIGHMARK_REST_API.replace(
  '/digital',
  '',
);

// SDK initializers that do not use the Config API
export const initializeNonConfigSDKs = () => {
  League.overwriteRoutePaths({
    [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
    [LEAGUE_MODULE_NAMES.careDiscovery]: '/care-discovery',
  });

  highmarkCommon.config({
    highmarkContentUrl: import.meta.env.VITE_HIGHMARK_CONTENT_URL,
    highmarkMemberPortalUrl: import.meta.env.VITE_HIGHMARK_MEMBER_PORTAL_URL,
    highmarkRestApiUrl: import.meta.env.VITE_HIGHMARK_REST_API,
    highmarkSsoAppUrl: import.meta.env.VITE_HIGHMARK_SSO_APP_URL,
  });

  formsLibrary.config({
    HIGHMARK_API_URL: formsLibraryApiUrl,
    HIGHMARK_CDN_URL: import.meta.env.VITE_HIGHMARK_CONTENT_URL,
    LOCAL_URL: 'https://app.local.league.dev:4000',
    STAGING_URL: 'https://league-hm-firebase-iel-staging.league.dev',
  });
};
