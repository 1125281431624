import React from 'react';
import { DropdownSection } from '@leagueplatform/web-common-components';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { BrandingInfo } from 'hooks/use-get-app-init-details';
import { useNavLinksList } from 'hooks/use-nav-links-list.hook';
import { DesktopNavbar } from './desktop-navbar.component';
import { MobileNavbar } from './mobile-navbar.component';

export const Navbar: React.FC<{
  userDropdownConfig: DropdownSection[];
  brandingInfo?: BrandingInfo;
}> = ({ userDropdownConfig, brandingInfo }) => {
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  const { navLinks } = useNavLinksList();
  return isMobile ? (
    <MobileNavbar
      userDropdownConfig={userDropdownConfig}
      brandingInfo={brandingInfo}
      navLinks={navLinks}
    />
  ) : (
    <DesktopNavbar
      userDropdownConfig={userDropdownConfig}
      brandingInfo={brandingInfo}
      navLinks={navLinks}
    />
  );
};
