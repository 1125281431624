import {
  ErrorContext,
  captureAppError,
  combineErrorContexts,
} from '@leagueplatform/observability';
import { SENTRY_CONSTANTS } from 'common/constants';
import { highmarkCommon } from 'highmark-common.config';

/**
 * Function to make a fetch call to Highmark Endpoint.
 * @param path : Path of the API to call
 * @param token : Partner Access token to fetch using Identity Partner API
 * @param options : Any extra options to apply to the request. It can include method, body, etc.
 */

export const highmarkFetch = async (
  path: String,
  token: String,
  options: RequestInit = {},
  errorContext: ErrorContext = {},
) => {
  const url = `${highmarkCommon.getConfig().highmarkRestApiUrl}${path.replace(
    /^\/?/,
    '/',
  )}`;

  const requestOptions = {
    method: 'GET',
    ...options,
    headers: new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...(options.headers || {}),
    }),
  };

  const promise = fetch(url, requestOptions);

  const response = await promise;

  // capture error responses for observability
  if (!response.ok) {
    const body = await response.clone().text();
    captureAppError(
      new Error(body),
      combineErrorContexts([
        {
          errorName: `Highmark Fetch Api Response Error - ${path}`,
          context: {
            'Response Info': {
              ok: response.ok,
              redirected: response.redirected,
              response: body,
              status: response.status,
              type: response.type,
              url: response.url,
              'highmark-trace-id': response.headers.get('uuid'),
            },
          },
          ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
          tags: { requestUrl: response.url },
        },
        errorContext,
      ]),
    );
  }

  return promise;
};
