import { useState, useEffect } from 'react';
import { TEST_CONFIG_FLAGS } from 'types/highmark-common.types';
import { getTestConfigFlags } from 'utils/get-test-config-flags.util';

type TEST_CONFIG_FLAGS_TYPE = {
  [key in keyof typeof TEST_CONFIG_FLAGS]?: string | null;
};

/**
 * Hook that checks the existence of localStorage items that correspond to flags
 * that enable certain features or behaviour in the app. This is mainly used for testing
 * or debugging app features that would otherwise be hard to reproduce.
 * This is only used for non production environments (dev, staging)
 *
 * @returns flags defined by TEST_CONFIG_FLAGS
 */
export const useTestConfigFlags = () => {
  const [flags, setFlags] = useState<TEST_CONFIG_FLAGS_TYPE>({});

  useEffect(() => {
    const configFlags = getTestConfigFlags();
    setFlags(configFlags);
  }, []);

  return { ...flags };
};
