import { useUrlParam } from '@leagueplatform/web-common';
import { getContentMapping } from 'api/get-content-mapping';
import { useQuery } from 'react-query';

export const GET_LEGAL_DETAILS_QUERY_KEY = 'get_legal_details_query_key';

export const useLegalContent = () => {
  const { urlParam } = useUrlParam('id');

  return useQuery(
    [GET_LEGAL_DETAILS_QUERY_KEY, urlParam],
    () => (urlParam ? getContentMapping(urlParam) : null),
    {
      enabled: !!urlParam,
      select: (data) => ({
        title: data?.data?.content?.name,
        body: data?.data?.content?.richText,
      }),
    },
  );
};
