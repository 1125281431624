import { useIntl } from '@leagueplatform/locales';
import { AssetLinkProps } from 'components/asset-links/asset-link.component';
import AppStoreTransparent from 'assets/mobile-app-icons/app-store-transparent.svg';
import PlayStoreTransparent from 'assets/mobile-app-icons/play-store-transparent.svg';

export const useMobileAppDownloadLinks = (
  appStoreUrl: string,
  playStoreUrl: string,
) => {
  const { formatMessage } = useIntl();
  const mobileAppDownloadLinks: AssetLinkProps[] = [
    {
      src: AppStoreTransparent,
      alt: formatMessage({ id: 'DISCLAIMER_CTA_APPSTORE' }),
      url: appStoreUrl,
    },
    {
      src: PlayStoreTransparent,
      alt: formatMessage({ id: 'DISCLAIMER_CTA_PLAYSTORE' }),
      url: playStoreUrl,
    },
  ];

  return { mobileAppDownloadLinks };
};
