import { Auth, Implementation } from '@leagueplatform/auth';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { useEffect, useState } from 'react';

export const useIsAuthenticated = () => {
  const isEmbedded = Auth.implementation === Implementation.EMBEDDED;
  const [isAuthenticated, setAuthenticated] = useState<boolean | undefined>(
    /**
     * The Highmark apps can initialize either in standalone auth mode or in
     * embedded auth mode. With the latter, the app is displayed in a mobile
     * webview, and we receive authentication from the mobile app.
     * When in embedded mode, we ASSUME that we're authenticated and rely on
     * EmbeddedAuth to throw an error if authentication is not passed.
     * This keeps the `use-is-authenticated` hook usable whether the app is initialized
     * in standalone mode or in embedded mode.
     * When in standalone mode, we default to `undefined` to match the useAuth
     * hook's behavior from the auth-utils module
     */
    () => (isEmbedded ? true : undefined),
  );

  useEffect(() => {
    let isMounted: boolean = true;
    if (!isEmbedded) {
      StandaloneAuth.client.isAuthenticated().then((authenticated) => {
        if (isMounted) {
          setAuthenticated(authenticated);
        }
      });
    }

    // clean up subscription
    return () => {
      isMounted = false;
    };
  }, [isEmbedded]);

  return { isAuthenticated };
};
