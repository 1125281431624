import { useIntl } from '@leagueplatform/locales';
import { usePreferredFirstName } from './use-preferred-first-name';

const greetingIDs: { [key: string]: string } = {
  morning: 'GOOD_MORNING',
  afternoon: 'GOOD_AFTERNOON',
  evening: 'GOOD_EVENING',
  morning_name: 'GOOD_MORNING_WITH_NAME',
  afternoon_name: 'GOOD_AFTERNOON_WITH_NAME',
  evening_name: 'GOOD_EVENING_WITH_NAME',
};

const getGreeting = () => {
  const hours = new Date(Date.now()).getHours();
  const MORNING = 12;
  const AFTERNOON = 18;
  if (hours < MORNING) return 'morning';
  if (hours < AFTERNOON) return 'afternoon';
  return 'evening';
};

export const useNamedGreeting = () => {
  const { formatMessage } = useIntl();

  const { data } = usePreferredFirstName();

  const greeting = getGreeting();
  const greetingSuffix = data.preferredFirstName ? '_name' : '';
  const namedGreeting = formatMessage(
    { id: greetingIDs[greeting + greetingSuffix] },
    { name: data.preferredFirstName },
  );

  return { namedGreeting };
};
