import React, { useEffect } from 'react';
import {
  LottieComponentProps,
  useLottieAnimation,
} from '@leagueplatform/lottie';
import { usePrefersReducedMotion } from '@leagueplatform/web-common';
import { Box } from '@leagueplatform/genesis-core';

const animationData = import('assets/lottie/cycling-animation.lottie.json');

export interface AnimationComponentProps extends LottieComponentProps {
  shouldPlay?: boolean;
}

export default function CelebrationCyclingAnimation({
  animationRef,
  lottieConfig: configOverrides,
  shouldPlay,
}: AnimationComponentProps) {
  const prefersReducedMotion = usePrefersReducedMotion();

  const lottieConfig = {
    loop: true,
    autoplay: shouldPlay,
    ...configOverrides,
  };
  const { animationContainerRef, animation } = useLottieAnimation({
    lottieConfig,
    animationRef,
    animationData,
  });

  useEffect(() => {
    if (shouldPlay) {
      animation?.goToAndPlay(0);
    }
  }, [shouldPlay, animation]);

  return prefersReducedMotion ? null : (
    <Box css={{ opacity: shouldPlay ? 1 : 0 }} ref={animationContainerRef} />
  );
}
