import { GDSTheme } from '@leagueplatform/genesis-core';
import { spacing } from './spacing';
import { colors } from './colors';
import { borderRadii } from './borderRadii';
import { shadows } from './shadows';
import { fonts } from './fonts';
import { typography } from './typography';
import { rem } from './rem';
import { borderWidths } from './borderWidths';
import { zIndices } from './zIndices';
import { transitions } from './transitions';

export const theme: GDSTheme = {
  spacing,
  colors,
  borderRadii,
  borderWidths,
  shadows,
  typography,
  fonts,
  zIndices,
  transitions,
  rem,
};

export { spacing, colors, shadows, fonts };
