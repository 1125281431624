import React from 'react';
import { ParagraphText, StatusBanner } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useGetHsaRegistrationEligibility } from 'hooks/use-get-hsa-registration-eligibility.hook';

export const HsaPendingBanner = () => {
  const { formatMessage } = useIntl();
  const { data, isLoading } = useGetHsaRegistrationEligibility();
  const isHsaPending = !isLoading && data.status === 'Pending';

  if (!isHsaPending) {
    return null;
  }

  return (
    <StatusBanner
      status="info"
      title={formatMessage({ id: 'HSA_PENDING_BANNER_TITLE' })}
      icon="statusInformationCircleFill"
    >
      <ParagraphText>
        {formatMessage({ id: 'HSA_PENDING_BANNER_BODY' })}
      </ParagraphText>
    </StatusBanner>
  );
};
