import { useEffect } from 'react';
import { useUrlParam } from '@leagueplatform/web-common';
import { QUERY_PARAM_KEYS } from 'common/sso-constants';
import { LinkHandlerClass } from 'utils/link-handler';

export const useSsoDeepLink = (linkHandler: LinkHandlerClass) => {
  const { removeParam, urlParam } = useUrlParam(QUERY_PARAM_KEYS.ssoDeeplink);

  useEffect(() => {
    if (urlParam) {
      const targetUrl = urlParam;
      removeParam();
      linkHandler.handleLink(targetUrl);
    }
  }, [urlParam, linkHandler, removeParam]);
};
