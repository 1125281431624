import {
  captureAppError,
  captureAppMessage,
  captureMessage,
} from '@leagueplatform/observability';
import { AppInitDetails } from 'api/get-app-init-details';
import { AUTH0_ERROR_TYPES, SENTRY_CONSTANTS } from 'common/constants';

export const captureUserIneligibleEvent = (data: AppInitDetails) => {
  if (!data?.eligibilityInfo?.eligible) {
    captureMessage('User Not Eligible to access application.', {
      context: {
        Context: {
          info: 'User successfully logged in but is not eligible for this application.',
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      severityLevel: 'info',
    });
  }
};

export const captureSsoError = (error: Error, ssoUrl: string) => {
  captureAppError(error, {
    errorName: `SSO External Link Error - ${error.message}`,
    context: {
      Context: {
        ssoUrl,
      },
    },
    ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
    tags: {
      sso: ssoUrl,
    },
  });
};

export const captureAuthedWebviewError = (error: Error, webviewUrl: string) => {
  captureAppError(error, {
    errorName: `Authed Webview Error - ${error.message}`,
    context: {
      Context: {
        webviewUrl,
      },
    },
    ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
    tags: {
      webview: webviewUrl,
    },
  });
};

export const captureAuthenticationError = (error: {
  type: string;
  description: string | null;
}) => {
  if (
    error.type &&
    error.type.toLowerCase().includes(AUTH0_ERROR_TYPES.LOGIN_REQUIRED)
  ) {
    // If the error message is due to the user timing out, then we would like to capture this
    // as a warning on Sentry, all other use cases should be handled as an error
    captureAppMessage(`Auth0 Error - ${error.type}`, {
      context: {
        Context: {
          info: `User has been logged out due to inactivity, a new login is required - type: ${error.type}, description: ${error.description}`,
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      severityLevel: 'warning',
    });
  } else {
    captureAppError(new Error(error.type), {
      errorName: `Auth0 Error - ${error.type}`,
      context: {
        Context: {
          info: `an authentication error has occurred - type: ${error.type}, description: ${error.description}`,
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
    });
  }
};
