interface LanguageAssistanceItem {
  id: number;
  title: string;
  description: string;
  isRtlLanguage: boolean;
  languageCode: string;
}

export const LANGUAGE_ASSISTANCE_TITLE_DESCRIPTIONS_CONFIG: LanguageAssistanceItem[] =
  [
    {
      id: 1,
      title: 'العربية',
      description:
        'تنبيه: إذا كنت تتحدث اللغة العربية، فهناك خدمات المعاونة في اللغة المجانية متاحة لك. اتصل بالرقم الموجود خلف بطاقة هويتك (جهاز الاتصال لذوي صعوبات السمع والنطق: 711).',
      isRtlLanguage: true,
      languageCode: 'arb',
    },
    {
      id: 2,
      title: '中文',
      description:
        '请注意：如果您说中文，可向您提供免费语言协助服务。请拨打您的身份背面的号码（TTY：711）。',
      isRtlLanguage: false,
      languageCode: 'zh',
    },
    {
      id: 3,
      title: 'FRANÇAIS',
      description:
        "ATTENTION: Si vous parlez français, les services d'assistance linguistique, gratuitement, sont à votre disposition. Appelez le numéro au dos de votre carte d'identité (TTY: 711).",
      isRtlLanguage: false,
      languageCode: 'fr',
    },
    {
      id: 4,
      title: 'CREOLE FRANÇAIS',
      description:
        'ATTENTION: Si c’est créole que vous connaissez, il y a un certain service de langues qui est gratis et disponible pour vous-même. Composez le numéro qui est au dos de votre carte d’identité. (TTY: 711).',
      isRtlLanguage: false,
      languageCode: 'fr',
    },
    {
      id: 5,
      title: 'DEUTSCH',
      description:
        'ACHTUNG: Wenn Sie Deutsch sprechen, steht Ihnen unsere fremdsprachliche Unterstützung kostenlos zur Verfügung. Rufen Sie dazu die auf der Rückseite Ihres Versicherungsausweises (TTY: 711) aufgeführte Nummer an.',
      isRtlLanguage: false,
      languageCode: 'de',
    },
    {
      id: 6,
      title: 'ગુજરાતી',
      description:
        'ધ્યાન આપશોઃ જો તમે ગુજરાતી ભાષા બોલતા હો, તો ભાષા સહાયતા સેવાઓ, મફતમાં તમને ઉપલબ્ધ છે. તમારા ઓળખપત્રના પાછળના ભાગે આવેલા નંબર પર ફોન કરો (TTY: 711).',
      isRtlLanguage: false,
      languageCode: 'guj',
    },
    {
      id: 7,
      title: 'HMONG',
      description:
        'LUS NCO TSEG: Yog koj hais lus Hmoob, muaj kev pab txhais lus pub dawb rau koj. Hu rau tus xovtooj nyob sab tom qab ntawm koj daim npav ID (TTY: 711).',
      isRtlLanguage: false,
      languageCode: 'hmn',
    },
    {
      id: 8,
      title: 'ITALIANO',
      description:
        "ATTENZIONE: se parla italiano, per lei sono disponibili servizi di assistenza linguistica a titolo gratuito. Contatti il numero riportato sul retro della sua carta d'identità (TTY: 711).",
      isRtlLanguage: false,
      languageCode: 'it',
    },
    {
      id: 9,
      title: '日本語',
      description:
        '注： 日本語が母国語の方は言語アシスタンス・サービスを無料でご利用いただけます。ID カードの裏に明記されている番号に電話をおかけください (TTY: 711)。',
      isRtlLanguage: false,
      languageCode: 'ja',
    },
    {
      id: 10,
      title: 'ខ្មែរ',
      description:
        'ប្រការចងចាំ៖ បើលោកអ្នកនិយាយ ភាសាខ្មែរ ហើយត្រូវការសេវាកម្មជំនួយផ្នែកភាសាដែលអាចផ្ដល់ជូនលោកអ្នកដោយឥតគិតថ្លៃ។ សូមទូរស័ព្ទទៅលេខដែលមាននៅលើខ្នងកាតសម្គាល់របស់របស់លោកអ្នក (TTY: 711)។',
      isRtlLanguage: false,
      languageCode: 'km',
    },
    {
      id: 11,
      title: '한국어',
      description:
        '알림: 한국어를 사용하시는 분들을 위해 무료 통역이 제공됩니다. ID 카드 뒷면에 있는 번호로 전화하십시오 (TTY: 711).',
      isRtlLanguage: false,
      languageCode: 'ko',
    },
    {
      id: 12,
      title: 'ພາສາລາວ',
      description:
        'ເຊີນຊາບ: ຖ້າຫາກວ່າທ່ານເວົ້າ ພາສາລາວ, ການບໍລິການການຊ່ວຍເຫຼືອດ້ານພາສາ, ໂດຍບໍ່ເສຍຄ່າ, ແມ່ນມີໃຫ້ທ່ານ. ໂທຫາເບີຢູ່ດ້ານຫຼັງຂອງບັດສະມາຊິກ (ID) ຂອງທ່ານ.',
      isRtlLanguage: false,
      languageCode: 'lo',
    },
    {
      id: 13,
      title: 'नेपाली',
      description:
        'ध्यान दिनुहोस्: यदि तपाईं नेपाली भाषा बोल्नुहुन्छ भने, तपाईंका लागि भाषा सहायता सेवाहरू नि:शुल्क उपलब्ध हुन्छन्। तपाईंको आइडी कार्डको पछाडि भागमा रहेको नम्बर (TTY: 711) मा फोन गर्नुहोस्।',
      isRtlLanguage: false,
      languageCode: 'ne',
    },
    {
      id: 14,
      title: 'PENNSILFAANISCH DEITSCH',
      description:
        'Geb Acht: Wann du Deitsch schwetzscht, kannscht du en Dolmetscher griege, un iss die Hilf Koschdefrei. Kannscht du die Nummer an deinre ID Kard dahinner uffrufe (TTY: 711).',
      isRtlLanguage: false,
      languageCode: 'de',
    },
    {
      id: 15,
      title: 'فارسی',
      description:
        ' توجه: اگر شما به زبان فارسی صحبت می کنید، خدمات کمک زبان، به صورت رایگان، در دسترس شماست. با شماره واقع در پشت کارت شناسایی خود ( TTY: 711) تماس بگیرید.',
      isRtlLanguage: true,
      languageCode: 'fa',
    },
    {
      id: 16,
      title: 'POLSZCZYZNA',
      description:
        'UWAGA: Dla osób mówiących po polsku dostępna jest bezpłatna pomoc językowa. Zadzwoń pod numer podany na odwrocie karty ubezpieczenia zdrowotnego (TTY: 711).',
      isRtlLanguage: false,
      languageCode: 'pl',
    },
    {
      id: 17,
      title: 'PORTUGUÊS',
      description:
        'ATENÇÃO: Se a sua língua é o português, temos atendimento gratuito para você no seu idioma. Ligue para o número no verso da sua identidade (TTY: 711).',
      isRtlLanguage: false,
      languageCode: 'pt',
    },
    {
      id: 18,
      title: 'РУССКИЙ',
      description:
        'ВНИМАНИЕ: Если вы говорите по-русски, вы можете воспользоваться бесплатными услугами языковой поддержки. Позвоните по номеру, указанному на обороте вашей идентификационной карты (номер для текст-телефонных устройств (TTY): 711).',
      isRtlLanguage: false,
      languageCode: 'ru',
    },
    {
      id: 19,
      title: 'ESPAÑOL',
      description:
        'ATENCIÓN: Si usted habla español, servicios de asistencia lingüística, de forma gratuita, están disponibles para usted. Llame al número en la parte posterior de su tarjeta de identificación (TTY: 711).',
      isRtlLanguage: false,
      languageCode: 'es',
    },
    {
      id: 20,
      title: 'TAGALOG',
      description:
        'ATENSYON: Kung nagsasalita ka ng Tagalog, may makukuha kang mga libreng serbisyong tulong sa wika. Tawagan ang numero sa likod ng iyong ID card (TTY: 711).',
      isRtlLanguage: false,
      languageCode: 'tl',
    },
    {
      id: 21,
      title: 'ไทย',
      description:
        'โปรดทราบ: หากคุณพูด ไทย, มีบริการช่วยเหลือด้านภาษาให้คุณโดยไม่มีค่าใช้จ่าย โทรไปยังหมายเลขที่อยู่ด้านหลังบัตรประจำตัวประชาชนของคุณ (TTY: 711)',
      isRtlLanguage: false,
      languageCode: 'th',
    },
    {
      id: 22,
      title: 'اردو',
      description:
        'توجہ فرمائیں: اگر آپ اردو بولتے ہیں، زبان معاونت سروس، مفت میں آپ کے لیے دستیاب ہے۔ اپنے شناختی کارڈ کی پشت پر درج شدہ نمبر پر کال کریں (TTY: 711)۔',
      isRtlLanguage: true,
      languageCode: 'ur',
    },
    {
      id: 23,
      title: 'TIẾNG VIỆT',
      description:
        'CHÚ Ý: Nếu quý vị nói tiếng Việt, chúng tôi cung cấp dịch vụ hỗ trợ ngôn ngữ miễn phí cho quý vị. Xin gọi số điện thoại ở mặt sau thẻ ID của quý vị (TTY: 711).',
      isRtlLanguage: false,
      languageCode: 'vi',
    },
  ];
