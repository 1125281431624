import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import { history, History } from '@leagueplatform/routing';
import { DEFAULT_PARTNER_ID } from 'common/constants';
import {
  LINK_TYPES,
  QUERY_PARAM_KEYS,
  SSO_READY_LISTENER_TIMEOUT,
  WEBVIEW_MODAL_QUERY_PARAM_VALUE,
} from 'common/sso-constants';
import { sendAuthTokenIfReady } from './post-message-auth-helpers';
import { logger } from './logger';
import { captureSsoError } from './sentry-helpers';

export class LinkHandlerClass {
  #lastPassedUrl = '';

  #history: History | undefined;

  constructor(historyFromClass: History | undefined) {
    this.#history = historyFromClass;
  }

  handleLink = async (urlString: string, target?: string) => {
    // Must be an external URL, or else show error
    if (!isAbsoluteUrl(urlString)) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'EXTERNAL_URL_REDIRECT_FAILED',
      });
      captureSsoError(new Error('Target URL is not absolute url.'), urlString);
      return;
    }
    const url = new URL(urlString);

    // Parse query params for identifiers we need, then remove from url
    const linkType = (
      url.searchParams.get(QUERY_PARAM_KEYS.linkType) || ''
    ).toLowerCase();
    url.searchParams.delete(QUERY_PARAM_KEYS.linkType);

    const partnerId =
      url.searchParams.get(QUERY_PARAM_KEYS.partnerId) || DEFAULT_PARTNER_ID;
    url.searchParams.delete(QUERY_PARAM_KEYS.partnerId);

    switch (linkType) {
      case LINK_TYPES.AUTHED_WEBVIEW: {
        this.#lastPassedUrl = url.toString();
        const params = new URLSearchParams(window.location.search);
        params.set(QUERY_PARAM_KEYS.modal, WEBVIEW_MODAL_QUERY_PARAM_VALUE);
        this.#history!.push({
          search: params.toString(),
        });
        break;
      }
      case LINK_TYPES.SSO: {
        const newWindow = window.open(url, '_blank');

        if (!newWindow) {
          toastActions.add({
            type: TOAST_STATUS.ERROR,
            textId: 'EXTERNAL_URL_REDIRECT_FAILED',
          });
          captureSsoError(
            new Error('Target SSO new window does not exist.'),
            urlString,
          );
        }
        let isAuthSuccessful = false;
        const messageHandler = async (event: MessageEvent) => {
          logger.log('Received message', event);
          try {
            const isAuthenticated = await sendAuthTokenIfReady(
              event,
              url.origin,
              newWindow,
              partnerId,
            );

            if (isAuthenticated) {
              isAuthSuccessful = true;
              window.removeEventListener('message', messageHandler);
            }
          } catch (e: any) {
            toastActions.add({
              type: TOAST_STATUS.ERROR,
              textId: 'EXTERNAL_URL_REDIRECT_FAILED',
            });
            captureSsoError(e instanceof Error ? e : new Error(e), urlString);
          }
        };
        window.addEventListener('message', messageHandler);
        // Remove event listener after a set time in case no event is received.
        setTimeout(() => {
          if (!isAuthSuccessful) {
            window.removeEventListener('message', messageHandler);
            captureSsoError(
              new Error(
                `Could not send auth token, ready message not received within ${SSO_READY_LISTENER_TIMEOUT}ms from new window.`,
              ),
              urlString,
            );
            toastActions.add({
              type: TOAST_STATUS.ERROR,
              textId: 'EXTERNAL_URL_REDIRECT_FAILED',
            });
          }
        }, SSO_READY_LISTENER_TIMEOUT);

        break;
      }
      default:
        window.open(url, target || '_blank', 'noopener,noreferrer');
    }
  };

  get lastPassedUrl() {
    return this.#lastPassedUrl;
  }
}

export const LinkHandler = new LinkHandlerClass(history);
