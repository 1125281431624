import { BodyOne, Box, Flex, Image } from '@leagueplatform/genesis-commons';
import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import SavingPiggyIcon from 'assets/icons/saving-piggy-dollars.svg';
import MedicalInstrumentIcon from 'assets/icons/medical-instrument-stethoscope.svg';
import BaggageIcon from 'assets/icons/baggage.svg';

export const HsaBody = () => {
  const { formatMessage } = useIntl();
  const hsaAdvantages = [
    {
      icon: (
        <Image
          src={MedicalInstrumentIcon}
          width="oneAndHalf"
          alt={formatMessage({ id: 'MEDICAL_INSTRUMENT_ICON' })}
        />
      ),
      contentId: 'HSA_PAGE_ADVANTAGE_1',
    },
    {
      icon: (
        <Image
          src={SavingPiggyIcon}
          alt={formatMessage({ id: 'SAVING_PIGGY_ICON' })}
          width="oneAndHalf"
        />
      ),
      contentId: 'HSA_PAGE_ADVANTAGE_2',
    },
    {
      icon: (
        <Image
          src={BaggageIcon}
          alt={formatMessage({ id: 'BAGGAGE_ICON' })}
          width="oneAndHalf"
        />
      ),
      contentId: 'HSA_PAGE_ADVANTAGE_3',
    },
  ];

  return (
    <>
      <BodyOne>{formatMessage({ id: 'HSA_PAGE_CONTENT_1' })}</BodyOne>
      <Box marginY="one">
        <BodyOne fontWeight="bold" marginY="half">
          {formatMessage({ id: 'HSA_PAGE_ADVANTAGE_HEADER' })}
        </BodyOne>
        <Box>
          {hsaAdvantages.map((advantage) => (
            <Flex key={advantage.contentId} marginBottom="one">
              <span>{advantage.icon}</span>
              <BodyOne marginLeft="one">
                {formatMessage({ id: advantage.contentId })}
              </BodyOne>
            </Flex>
          ))}
        </Box>
      </Box>
      <BodyOne>{formatMessage({ id: 'HSA_BODY' })}</BodyOne>
    </>
  );
};
