import React from 'react';
import {
  ContainerWidgetItem,
  useGetWidgetData,
} from '@leagueplatform/masonry-api';
import { MASONRY } from 'common/constants';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import {
  GDSStatus,
  ParagraphText,
  StatusBanner,
} from '@leagueplatform/genesis-core';

type BannerData = {
  description: string;
  heading: string;
  image: string;
  image_alt_text: string;
  current_label: string;
};

export const StatusBannerMasonryWidget = ({
  widget,
}: {
  widget: ContainerWidgetItem;
}) => {
  const {
    data: widgetData,
    isLoading,
    error,
  } = useGetWidgetData<BannerData>(widget, MASONRY.API_VERSION);

  if (isLoading) {
    return <SkeletonBox height="60px" />;
  }

  if (!widgetData || error) {
    return null;
  }

  return (
    <StatusBanner
      title={widgetData.attributes.heading}
      status={widgetData.attributes.current_label as GDSStatus}
    >
      <ParagraphText size="sm">
        {widgetData.attributes.description}
      </ParagraphText>
    </StatusBanner>
  );
};
