import React from 'react';

export const AboutMeRoutes = React.lazy(() =>
  import('@leagueplatform/about-me').then((module) => ({
    default: module.AboutMeRoutes,
  })),
);

export const WalletRoutes = React.lazy(() =>
  import('@leagueplatform/wallet').then((module) => ({
    default: module.WalletRoutes,
  })),
);

export const AchievementsRoutes = React.lazy(() =>
  import('@leagueplatform/rewards-achievements').then((module) => ({
    default: module.AchievementsRoutes,
  })),
);

export const HealthProfileRoutes = React.lazy(() =>
  import('@leagueplatform/health-profile').then((module) => ({
    default: module.HealthProfileRoutes,
  })),
);

export const CareCollectionsRoutes = React.lazy(() =>
  import('@leagueplatform/care-collections').then((module) => ({
    default: module.CareCollectionsRoutes,
  })),
);

export const CareDiscoveryRoutes = React.lazy(() =>
  import('@leagueplatform/care-discovery').then((module) => ({
    default: module.CareDiscoveryRoutes,
  })),
);

export const ClaimsRoutes = React.lazy(() =>
  import('@leagueplatform/claims').then((module) => ({
    default: module.ClaimsRoutes,
  })),
);

export const CoordinationOfBenefitsRoutes = React.lazy(() =>
  import('@leagueplatform/engen-capability-cob-library').then((module) => ({
    default: module.CobLibraryRoutes,
  })),
);

export const HealthJourneyRoutes = React.lazy(() =>
  import('@leagueplatform/health-journey').then((module) => ({
    default: module.HealthJourneyRoutes,
  })),
);

export const MessagingRoutes = React.lazy(() =>
  import('@leagueplatform/messaging').then((module) => ({
    default: module.MessagingRoutes,
  })),
);

export const HomePage = React.lazy(() =>
  import('pages/home/home.page').then((module) => ({
    default: module.HomePage,
  })),
);

export const SupportPage = React.lazy(() =>
  import('@highmark-web/highmark-common').then((module) => ({
    default: module.SupportPage,
  })),
);

export const LiveChatPage = React.lazy(() =>
  import('@leagueplatform/live-chat').then((module) => ({
    default: module.LiveChatRoutes,
  })),
);
