import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import {
  Box,
  HeadingText,
  StackItem,
  StackLayout,
  TextAction,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { PageHeaderBackground } from '@leagueplatform/web-common-components';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';

export const PageHeaderMobile = ({
  headingId,
  headingText,
  showBackButton = true,
}: {
  headingId?: string;
  headingText?: string;
  showBackButton?: boolean;
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(100);
  const isLaptopDown = useMediaQuery(queryHelpers.down('laptop'));
  const pageHeaderBackgroundHeightPadding = isLaptopDown ? 60 : 112;

  useLayoutEffect(() => {
    const reportWindowSize = () => {
      setContentHeight(contentRef?.current?.clientHeight ?? contentHeight);
    };
    if (contentHeight !== undefined)
      window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, [contentHeight]);

  useEffect(() => {
    if (contentRef?.current?.clientHeight)
      setContentHeight(contentRef.current.clientHeight);
  }, []);

  return (
    <Box
      css={{
        marginTop: '$two',
        marginBottom: '$two',
        '@laptop': {
          marginTop: '56px',
          marginBottom: '56px',
        },
        '@desktop': {
          marginTop: '56px',
          marginBottom: '56px',
        },
      }}
    >
      <PageHeaderBackground
        height={`${contentHeight + pageHeaderBackgroundHeightPadding / 2}px`}
        pageHeaderBackgroundHeight={`${
          contentHeight + pageHeaderBackgroundHeightPadding
        }px`}
      >
        <StackLayout>
          <StackItem
            horizontalAlignment="start"
            verticalAlignment="top"
            ref={contentRef}
          >
            {showBackButton && (
              <TextAction
                onClick={() => history.goBack()}
                as="button"
                icon="interfaceChevronLeft"
                css={{
                  color: '$onSurfaceIconPrimary',
                  marginBottom: '$half',
                }}
              >
                {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                {''}
              </TextAction>
            )}
            {headingText || headingId ? (
              <HeadingText
                level="1"
                size={{
                  '@initial': 'xl',
                  '@laptop': 'xxl',
                  '@desktop': 'xxl',
                }}
              >
                {headingText ||
                  (headingId ? formatMessage({ id: headingId }) : '')}
              </HeadingText>
            ) : (
              <Box css={{ height: '$three' }} />
            )}
          </StackItem>
        </StackLayout>
      </PageHeaderBackground>
    </Box>
  );
};
