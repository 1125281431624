import { useHistory } from '@leagueplatform/routing';
import { ErrorStateProps } from '@leagueplatform/web-common-components';

export enum ERROR_TYPES {
  API_ERROR = 'api_error',
  NO_CONNECTION = 'no_connection',
  NOT_ELIGIBLE = 'not_eligible',
  OVER_365_DAYS = 'over_365_days',
}

export type ErrorMessageProps = {
  errorType?: ERROR_TYPES;
  buttonId?: string;
  headingString?: string;
  bodyString?: string;
  buttonAction?: () => void;
};

type ErrorBody = {
  headingId: string;
  bodyId: string;
  buttonId?: string;
  buttonAction?: () => void;
};

export const useErrorMessage = ({
  errorType = ERROR_TYPES.API_ERROR,
  buttonId,
  headingString,
  bodyString,
  buttonAction,
}: ErrorMessageProps) => {
  const history = useHistory();

  const ErrorMessages: {
    [type in ERROR_TYPES]: ErrorBody;
  } = {
    [ERROR_TYPES.API_ERROR]: {
      headingId: 'ERROR_HEADING_GENERIC',
      bodyId: 'ERROR_CAPTION_BACKEND',
      buttonId: 'RELOAD',
      buttonAction: () => window.location.reload(),
    },
    [ERROR_TYPES.NO_CONNECTION]: {
      headingId: 'ERROR_HEADING_NO_CONNECTION',
      bodyId: 'ERROR_CAPTION_NO_CONNECTION',
    },
    [ERROR_TYPES.NOT_ELIGIBLE]: {
      headingId: 'ERROR_HEADING_NOT_ELIGIBLE',
      bodyId: 'ERROR_CAPTION_NOT_ELIGIBLE',
      buttonId: 'CLOSE',
      buttonAction: () => history.push('/sign-out'),
    },
    [ERROR_TYPES.OVER_365_DAYS]: {
      headingId: 'ERROR_HEADING_OVER_365_DAYS',
      bodyId: 'ERROR_CAPTION_OVER_365_DAYS',
      buttonId: 'CLOSE',
      buttonAction: () => history.push('/sign-out'),
    },
  };

  let errorContentData: ErrorStateProps = {
    headingString,
    headingId: ErrorMessages[errorType].headingId,
    bodyString,
    bodyId: ErrorMessages[errorType].bodyId,
  };

  if (
    ErrorMessages[errorType].buttonId &&
    ErrorMessages[errorType].buttonAction
  ) {
    errorContentData = {
      ...errorContentData,
      buttonId: buttonId || ErrorMessages[errorType].buttonId,
      onButtonClick: buttonAction || ErrorMessages[errorType].buttonAction,
    };
  }

  return errorContentData;
};
