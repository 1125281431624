import { useMemo } from 'react';
import { useGetHighmarkUserProfile } from 'hooks/use-get-user-profile';
import { useAnalyticsIdentify } from '@leagueplatform/web-common';

export const useHighmarkAnalyticsIdentify = (tenantId: string) => {
  const identifyOptions = useMemo(
    () => ({
      employer_group_id: '',
      tenant_id: tenantId,
    }),
    [tenantId],
  );
  return useAnalyticsIdentify(identifyOptions, useGetHighmarkUserProfile);
};
