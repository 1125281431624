import { useHistory, useLocation } from '@leagueplatform/routing';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { STORED_DEEPLINK_SESSION_STORAGE_KEY } from 'common/constants';
import { useEffect } from 'react';
import { clearHighmarkSessionRedirect } from 'utils/clear-session-redirect';

/**
 * This hook handles redirecting the user to the auth login page if the user is not authenticated.
 * If the user is visiting a deeplink URL, store the value in browser memory.
 * Once the user is authenticated and returns back to this app, fetch the deeplink value.
 * Redirect the user to the deeplink URL, then clear the stored deeplink URL.
 */
export const useAuthLoginRedirect = (isAuthenticated: boolean | undefined) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated === false) {
      const { pathname, search } = location;
      const storedDeeplinkPath = pathname + search;

      sessionStorage.setItem(
        STORED_DEEPLINK_SESSION_STORAGE_KEY,
        storedDeeplinkPath,
      );
      clearHighmarkSessionRedirect(APP_MANAGED_ROUTES.SIGN_IN);
    }

    if (isAuthenticated) {
      const storedDeeplink = sessionStorage.getItem(
        STORED_DEEPLINK_SESSION_STORAGE_KEY,
      );

      if (storedDeeplink) {
        history.push(storedDeeplink);
      }

      sessionStorage.removeItem(STORED_DEEPLINK_SESSION_STORAGE_KEY);
    }
  }, [isAuthenticated, history, location]);
};
