import { TEST_CONFIG_FLAGS } from 'types/highmark-common.types';
import { AppEnvironment, ENVIRONMENT } from '@leagueplatform/core';

export const getTestConfigFlags = (
  configFlag?: keyof typeof TEST_CONFIG_FLAGS,
) => {
  const newConfig: any = {};

  if (AppEnvironment.environment !== ENVIRONMENT.PRODUCTION) {
    Object.entries(TEST_CONFIG_FLAGS).forEach(([key, value]) => {
      newConfig[key] = localStorage.getItem(value);
    });
  }
  if (configFlag) {
    return newConfig[configFlag];
  }
  return newConfig;
};
