/**
 * Temporary Fix for sub domain switch over from myhighmark.com to member.myhighmark.com.
 * Based on the .env values, redirect the app to target URL if current domain matches
 * */

export const redirectOnDomainMatch = (renderFn: () => void) => {
  if (
    import.meta.env.VITE_SOURCE_REDIRECT_DOMAIN === window.location.origin &&
    import.meta.env.VITE_TARGET_REDIRECT_URL
  ) {
    window.location.replace(import.meta.env.VITE_TARGET_REDIRECT_URL);
  } else {
    renderFn();
  }
};
