import React, { useContext } from 'react';
import { PlanEndedBlockerPage } from 'pages/page-blocker/plan-ended-blocker.page';
import { PageEligibilityContext } from 'components/page-blocker/page-eligibility-context';
import { CONTENT_ROUTES, INELIGIBLE_STATES } from 'api/get-app-init-details';

export const PageBlockerContainer = ({
  children,
  contentRoute,
}: {
  children: JSX.Element;
  contentRoute: CONTENT_ROUTES;
}) => {
  const { [contentRoute]: targetRoute } = useContext(PageEligibilityContext);

  if (targetRoute === INELIGIBLE_STATES.NO_LONGER_EFFECTIVE) {
    return <PlanEndedBlockerPage contentRoute={contentRoute} />;
  }

  return children;
};
