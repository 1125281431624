import { GDSTypography } from '@leagueplatform/genesis-core';
import { fonts } from './fonts';

export const typography: GDSTypography = {
  headingOne: {
    fontFamily: fonts.headings,
    fontWeight: 'Semi Bold',
    lineHeight: 38,
    fontSize: 30,
    letterSpacing: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
  headingTwo: {
    fontFamily: fonts.headings,
    fontWeight: 'Semi Bold',
    lineHeight: 30,
    fontSize: 22,
    letterSpacing: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
  headingThree: {
    fontFamily: fonts.headings,
    fontWeight: 'Semi Bold',
    lineHeight: 26,
    fontSize: 18,
    letterSpacing: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
  headingFour: {
    fontFamily: fonts.headings,
    fontWeight: 'Semi Bold',
    lineHeight: 24,
    fontSize: 16,
    letterSpacing: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
  bodyOne: {
    fontFamily: fonts.body,
    fontWeight: 'Regular',
    lineHeight: 24,
    fontSize: 16,
    letterSpacing: 0,
    paragraphSpacing: 8,
    textCase: 'none',
    textDecoration: 'none',
  },
  bodyOneSecondary: {
    fontFamily: fonts.body,
    fontWeight: 'Regular',
    lineHeight: 24,
    fontSize: 16,
    letterSpacing: 0,
    paragraphSpacing: 8,
    textCase: 'none',
    textDecoration: 'none',
  },
  bodyTwo: {
    fontFamily: fonts.body,
    fontWeight: 'Regular',
    lineHeight: 20,
    fontSize: 14,
    letterSpacing: 0,
    paragraphSpacing: 8,
    textCase: 'none',
    textDecoration: 'none',
  },
  bodyTwoSecondary: {
    fontFamily: fonts.body,
    fontWeight: 'Regular',
    lineHeight: 20,
    fontSize: 14,
    letterSpacing: 0,
    paragraphSpacing: 8,
    textCase: 'none',
    textDecoration: 'none',
  },
  subtitleOne: {
    fontFamily: fonts.body,
    fontWeight: 'Bold',
    lineHeight: 24,
    fontSize: 16,
    letterSpacing: -0.2,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
  subtitleTwo: {
    fontFamily: fonts.body,
    fontWeight: 'Bold',
    lineHeight: 20,
    fontSize: 14,
    letterSpacing: -0.2,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
  buttonOne: {
    fontFamily: fonts.body,
    fontWeight: 'Semi Bold',
    lineHeight: 24,
    fontSize: 16,
    letterSpacing: -0.2,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
  buttonTwo: {
    fontFamily: fonts.body,
    fontWeight: 'Semi Bold',
    lineHeight: 20,
    fontSize: 14,
    letterSpacing: -0.2,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
  label: {
    fontFamily: fonts.body,
    fontWeight: 'Medium',
    lineHeight: 16,
    fontSize: 12,
    letterSpacing: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
  overline: {
    fontFamily: fonts.body,
    fontWeight: 'Bold',
    lineHeight: 16,
    fontSize: 12,
    letterSpacing: 0.6,
    paragraphSpacing: 0,
    textCase: 'uppercase',
    textDecoration: 'none',
  },
  caption: {
    fontFamily: fonts.body,
    fontWeight: 'Regular',
    lineHeight: 16,
    fontSize: 12,
    letterSpacing: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
  tab: {
    fontFamily: fonts.body,
    fontWeight: 'Medium',
    lineHeight: 12,
    fontSize: 10,
    letterSpacing: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    textDecoration: 'none',
  },
};
