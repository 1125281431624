import { useEffect, useState } from 'react';
import { StandaloneAuthInitConfig } from '@leagueplatform/auth-standalone';
import { LeagueConfig } from '@leagueplatform/core';
import { useMobileAuthListener } from 'hooks/useMobileAuthListener.hook';

export type StandaloneAuthConfig = StandaloneAuthInitConfig;

type EmbeddedAuthConfig = {
  getUserIdFromMobileHandoff: () => Promise<string>;
  getToken: () => Promise<string>;
};

export type AuthConfig = StandaloneAuthConfig | EmbeddedAuthConfig;

// Custom interface for League Config that does not include `auth` on the core config
// This is needed since auth config is set dynamically
export interface AuthlessLeagueConfig extends Omit<LeagueConfig, 'core'> {
  core: Omit<LeagueConfig['core'], 'auth'>;
}

export const useGetAuthConfig = (
  standaloneAuthConfig: StandaloneAuthConfig,
) => {
  // Default to true since we need to set the config whether we are in standalone or embedded mode
  const [isConfigLoading, setIsConfigLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<AuthConfig | undefined>(() => undefined);

  const {
    shouldUseMobileAuth,
    isLoading: isMobileLoading,
    mobileAuthUserId,
    mobileAuthToken,
  } = useMobileAuthListener();

  useEffect(() => {
    /**
     * We need to dynamically update the config once we receive it
     *
     * We need to differentiate between embedded mobile, which has a
     * loading state, and standalone, which does not. We also
     * only update the config if we need to load the value.
     */
    if (isConfigLoading) {
      // Check if we are in embedded or standalone auth mode
      if (shouldUseMobileAuth) {
        // We are operating in embedded auth mode
        if (!isMobileLoading) {
          // Only update the config values when we receive them from mobile
          const mobileConfig = {
            getUserIdFromMobileHandoff: () => Promise.resolve(mobileAuthUserId),
            getToken: () => Promise.resolve(mobileAuthToken),
          };
          setConfig(mobileConfig);
          // Clean up
          setIsConfigLoading(false);
        }
      } else {
        // We are operating in standalone auth mode
        setConfig(standaloneAuthConfig);
        // Clean up
        setIsConfigLoading(false);
      }
    }
  }, [
    standaloneAuthConfig,
    shouldUseMobileAuth,
    mobileAuthUserId,
    mobileAuthToken,
    isConfigLoading,
    isMobileLoading,
  ]);

  return { isLoading: isConfigLoading, config };
};
