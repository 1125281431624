export const APP_MANAGED_ROUTES = {
  ABOUT: '/about',
  ABOUT_BENEFICITY: '/about-beneficity',
  CARE_REDIRECT: '/get-care',
  COORDINATION_OF_BENEFITS: '/highmark-cob/NA',
  FORMS_LIBRARY: '/forms-library',
  HOME: '/home',
  HSA_ENROLLMENT: '/hsaEnrollment',
  MEMBER_REDIRECT: '/member',
  PRIVACY: '/privacy',
  LEGAL: '/legal',
  LEGAL_DETAILS_SUBPATH: '/legal-details',
  LIVE_CHAT: '/live-chat',
  SIGN_IN: '/sign-in',
  SIGN_IN_ALTERNATIVE: '/member/login', // This is a workaround needed to match mobile login path/deeplink, TODO: https://everlong.atlassian.net/browse/HMRK-7018
  SIGN_OUT: '/sign-out',
  SUPPORT: '/support',
  TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES:
    '/technical-assistance-and-all-languages',
  WALLET_REDIRECT: '/wallet',
};
